import React, { useState, useContext, useEffect } from "react";
import _ from "lodash";
import { Link, useNavigate } from "react-router-dom";
import { has } from "lodash";
import { useQuery } from "@apollo/client";
import gql from "graphql-tag";
import { routes } from "../../routes";
import { useAuth } from "../../context/authContext";
import { useUser } from "../../context/userContext";
import { useDepartmentFilter } from "../../context/departmentFilterContext";
import { FetchContext } from "../../context/fetchContext";
import { isAuthed } from "../../utils/authorization";
import styles from "./Sidebar.module.scss";
import {
  Avatar,
  Drawer,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Paper,
  Typography,
  Collapse,
  MenuItem,
  useTheme,
} from "@material-ui/core";
import Icon from "@mdi/react";
import { mdiMap, mdiHammerWrench, mdiChevronDown, mdiCogs, mdiDomain, mdiEye } from "@mdi/js";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import Menu from "../../components/Menu/Menu";
import Version from "../Version/Version";
import { isProd } from "../../utils/const";

const Sidebar = ({ params, org, open, handleClose, isDesktop }) => {
  const theme = useTheme();
  const { auth, handleSignout } = useAuth();
  const { user } = useUser();
  const { departmentFilter, setDepartmentFilter } = useDepartmentFilter();
  const { fetch } = useContext(FetchContext);

  const [collapse, setCollapse] = useState();
  const { data, loading, refetch } = useQuery(GET_PLANS, {
    variables: { id: params.org },
    fetchPolicy: "network-only",
    nextFetchPolicy: "cache-first",
  });
  const fiscalYear = org.fiscalYear;
  const navigate = useNavigate();

  const handleCollapse = (name) => () => {
    if (name === collapse) {
      setCollapse();
    } else {
      setCollapse(name);
    }
  };

  const handleClick = (id, sharedPlanId) => () => {
    setDepartmentFilter({ id, sharedPlanId });
    if (_.isNil(id)) {
      sessionStorage.removeItem("departmentFilterId");
    } else {
      sessionStorage.setItem("departmentFilterId", id);
    }

    if (_.isNil(sharedPlanId)) {
      sessionStorage.removeItem("departmentFilterSharedId");
    } else {
      sessionStorage.setItem("departmentFilterSharedId", sharedPlanId);
    }
  };

  const navigateToSettings = () => {
    navigate(`/${params.org}/settings`);
  };

  useEffect(() => {
    refetch();
  }, [fetch]);

  if (_.isNil(auth) || _.isEmpty(user) || loading) return null;
  const { name, profilePicture, position, auth: userAuth, plan } = user;

  const userPlans = _.get(data, "plans", []);
  const currentUserPlans = userPlans.filter((plan) => plan.year === fiscalYear);
  const hasCorporate = currentUserPlans.some((plan) => plan.departmentName === "Corporate");
  const selectedDept = currentUserPlans.find((plan) => plan.sharedPlanId === departmentFilter.sharedPlanId);

  const routesGroupedByType = _.groupBy(routes, (route) => route.type);

  const viewPreferencesByName = _.keyBy(org.viewPreferences || [], "name");

  const renderLinks = (routes = [], hideCallback, isNested, onCollapse) => {
    return routes.map((route) => {
      const { path, name, icon, disabled, collapseType } = route;
      if (hideCallback(route)) return null;
      if (disabled) {
        return <DisabledListItem isNested={isNested} icon={icon} name={name} key={name} />;
      } else if (_.isNil(path) && collapseType) {
        return (
          <NestedListItem
            key={name}
            icon={icon}
            name={name}
            collapseType={collapseType}
            auth={userAuth}
            renderLinks={renderLinks}
            routesGroupedByType={routesGroupedByType}
          />
        );
      } else {
        return (
          <Link to={`/${params.org}/${path}`} key={name} className={styles.link}>
            <ListItem button className={isNested ? styles.nested : undefined}>
              <ListItemIcon>
                <Icon path={icon} className={styles.icon} size={1} color="white" />
              </ListItemIcon>
              <ListItemText className={styles.text}>{name}</ListItemText>
            </ListItem>
          </Link>
        );
      }
    });
  };

  return (
    <Drawer
      open={open}
      onClose={handleClose}
      variant={isDesktop ? "persistent" : "temporary"}
      PaperProps={{ className: isDesktop ? styles.paper : styles.paperMobile }}
    >
      {/* add organization dropdown */}
      <Paper className={styles.user}>
        <div className={styles.userWrapper} style={{ marginBottom: theme.spacing(1) }}>
          <div className={styles.avatarWrapper}>
            <Avatar className={styles.avatar} src={profilePicture} onClick={navigateToSettings}>
              {name.first[0]}
              {name.last[0]}
            </Avatar>
          </div>
          <div className={styles.columnFlex}>
            <Typography className={styles.userName} align="center">
              {name.first} {name.last}
            </Typography>
            <Typography variant="subtitle2" align="center" className={styles.authState}>
              {userAuth} {has(plan, "departmentName") && <>{plan.departmentName}</>}
            </Typography>
          </div>
        </div>
        <Typography variant="subtitle2" align="center">
          {position}
        </Typography>

        <div className={styles.center}>
          <Menu
            button={
              <span className={styles.departmentSelector}>
                {_.get(selectedDept, "departmentName", "All Departments")}{" "}
                <Icon color="#fff" size={0.75} path={mdiChevronDown} className={styles.icon} />
              </span>
            }
          >
            <MenuItem onClick={handleClick(null, null)} disabled={departmentFilter.id === null}>
              All Departments
            </MenuItem>
            {data &&
              currentUserPlans.map(({ id, sharedPlanId, departmentName }) => (
                <MenuItem key={id} onClick={handleClick(id, sharedPlanId)} disabled={departmentFilter.id === id}>
                  {departmentName}
                </MenuItem>
              ))}
          </Menu>
        </div>
      </Paper>
      <div className={styles.wrapper}>
        <List component="nav" dense>
          {renderLinks(routesGroupedByType["dashboard"], (route) => !route.layout.includes(userAuth))}

          <ListItem button onClick={handleCollapse("My Company")}>
            <ListItemIcon>
              <Icon path={mdiDomain} className={styles.icon} size={1} color="white" />
            </ListItemIcon>
            <ListItemText className={styles.text}>My Company</ListItemText>
          </ListItem>
          <Collapse in={collapse === "My Company"}>
            {renderLinks(routesGroupedByType["my company"], (route) => !route.layout.includes(userAuth), true)}
          </Collapse>

          <ListItem button onClick={handleCollapse("Vision")}>
            <ListItemIcon>
              <Icon path={mdiEye} className={styles.icon} size={1} color="white" />
            </ListItemIcon>
            <ListItemText className={styles.text}>Vision</ListItemText>
          </ListItem>
          <Collapse in={collapse === "Vision"}>
            {renderLinks(
              (routesGroupedByType["vision"] || []).sort((a, b) =>
                _.get(viewPreferencesByName, `${a.props.name}.position`, 0) > _.get(viewPreferencesByName, `${b.props.name}.position`, 0)
                  ? 1
                  : -1
              ),
              (route) => !route.layout.includes(userAuth) || !viewPreferencesByName[route.props.name].show,
              true
            )}
          </Collapse>

          <ListItem button onClick={handleCollapse("Strategy")}>
            <ListItemIcon>
              <Icon path={mdiMap} className={styles.icon} size={1} color="white" />
            </ListItemIcon>
            <ListItemText className={styles.text}>Strategy</ListItemText>
          </ListItem>
          <Collapse in={collapse === "Strategy"}>
            {renderLinks(
              routesGroupedByType["strategy"],
              (route) => !route.layout.includes(userAuth) || (!hasCorporate && route.path === "3-year"),
              true
            )}
          </Collapse>
          <ListItem button onClick={handleCollapse("Execution")}>
            <ListItemIcon>
              <Icon path={mdiHammerWrench} className={styles.icon} size={1} color="white" />
            </ListItemIcon>
            <ListItemText>Execution</ListItemText>
          </ListItem>
          <Collapse in={collapse === "Execution"}>
            {renderLinks(routesGroupedByType["execution"], (route) => !route.layout.includes(userAuth), true)}
          </Collapse>
        </List>
        <List className={styles.list} dense>
          {renderLinks(routesGroupedByType["reports"], (route) => !route.layout.includes(userAuth))}
          {isAuthed(auth, "vantage facilitator") && (
            <>
              <ListItem button onClick={handleCollapse("System")}>
                <ListItemIcon>
                  <Icon path={mdiCogs} className={styles.icon} size={1} color="white" />
                </ListItemIcon>
                <ListItemText className={styles.text}>System</ListItemText>
              </ListItem>
              <Collapse in={collapse === "System"}>
                {renderLinks(
                  routesGroupedByType["system"],
                  (route) =>
                    !route.layout.includes(userAuth) || ((route.path === "system-tasks" || route.path === "saved-notifications") && isProd),
                  true
                )}
              </Collapse>
            </>
          )}
          <ListItem button onClick={handleSignout}>
            <ListItemIcon>
              <ExitToAppIcon className={styles.icon} fontSize="large" />
            </ListItemIcon>
            <ListItemText className={styles.text}>Sign out</ListItemText>
          </ListItem>
        </List>
      </div>
      <Version />
    </Drawer>
  );
};

export default Sidebar;

const DisabledListItem = ({ isNested, icon, name }) => {
  return (
    <ListItem className={isNested ? styles.nested : undefined} disabled>
      <ListItemIcon>
        <Icon path={icon} className={styles.icon} size={1} color="white" />
      </ListItemIcon>
      <ListItemText className={styles.text}>{name}</ListItemText>
    </ListItem>
  );
};

const NestedListItem = ({ icon, name, collapseType, auth, renderLinks, routesGroupedByType }) => {
  const [nestedCollapse, setNestedCollapse] = useState();

  const handleCollapse = (name) => () => {
    if (name === nestedCollapse) {
      setNestedCollapse();
    } else {
      setNestedCollapse(name);
    }
  };

  return (
    <>
      <ListItem button onClick={handleCollapse(collapseType)} className={styles.nested}>
        <ListItemIcon>
          <Icon path={icon} className={styles.icon} size={1} color="white" />
        </ListItemIcon>
        <ListItemText className={styles.text}>{name}</ListItemText>
      </ListItem>
      <Collapse in={nestedCollapse === collapseType}>
        {renderLinks(routesGroupedByType[`${collapseType}`], (route) => !route.layout.includes(auth), true)}
      </Collapse>
    </>
  );
};

const GET_PLANS = gql`
  query Sidebar_GetPlans($id: ID!) {
    plans(organization: $id, closed: false, category: "1 year") {
      id
      departmentName
      sharedPlanId
      year
    }
  }
`;
