import React from "react";
import styles from "./Dashboard.module.scss";
import _ from "lodash";

import { Box, useTheme } from "@material-ui/core";

import CalendarComponent from "../../components/Calendar/Calendar";

const Calendar = ({ org }) => {
  const theme = useTheme();

  return (
    <Box style={{ height: "100%", padding: theme.spacing(3) }}>
      {/* <Typography variant="body2" className={styles.itemTitle}>
        Calendar
      </Typography> */}
      <CalendarComponent org={org} />
    </Box>
  );
};

export default Calendar;
