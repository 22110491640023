import React, { useContext, useEffect } from "react";
import _ from "lodash";
import useForm from "../../hooks/useForm";
import { useMutation } from "@apollo/client";
import gql from "graphql-tag";
import { FetchContext } from "../../context/fetchContext";
import { SnackbarContext } from "../../context/snackbarContext";
import { useAuth } from "../../context/authContext";
import * as Yup from "yup";
import { useFormik, FormikProvider } from "formik";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Collapse,
} from "@material-ui/core";
import { isVantageAdmin } from "../../utils/authorization";
import { recursiveMergeCustomizer } from "../../utils/misc";
import { useUser } from "../../context/userContext";

const AddEditAdminDialog = ({ open, handleClose, admin, organizations }) => {
  const [updateUser] = useMutation(UPDATE_USER);
  const [createAdmin] = useMutation(CREATE_ADMIN);
  const [sendPasswordResetEmail, { loading: resetPwLoading }] = useMutation(SEND_PASSWORD_RESET_EMAIL);

  const { requestFetch } = useContext(FetchContext);
  const { snack } = useContext(SnackbarContext);
  const { auth: authCtx } = useAuth();
  const { refreshUser } = useUser();

  const isCreate = _.isNil(admin);

  // FORMIK START
  const schema = Yup.object().shape({
    name: Yup.object({
      first: Yup.string().required("First name is required"),
      last: Yup.string().required("Last name is required"),
    }),
    email: Yup.string().email("Email format must be valid").required("Email is required"),
    auth: Yup.string().required("Authorization is required"),
    organizations: Yup.array()
      .of(Yup.string().required("Organization is required"))
      .when("auth", {
        is: (val) => val === "vantage facilitator",
        then: (schema) => schema.min(1, "At least one organization is required"),
        otherwise: (schema) => schema.nullable(),
      }),
  });

  const getInitialValues = (admin) => {
    let initVals = {
      name: {
        first: "",
        last: "",
      },
      email: "",
      auth: "vantage facilitator",
      organizations: [],
    };

    if (!_.isNil(admin)) {
      initVals = _.mergeWith({}, initVals, admin, (objVal, srcVal) => recursiveMergeCustomizer(srcVal));
    }

    return initVals;
  };

  const formik = useFormik({
    initialValues: getInitialValues(admin),
    validationSchema: schema,
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      const { name, auth, organizations } = values;

      try {
        let res;
        if (isCreate) {
          res = await createAdmin({
            variables: {
              ...values,
              firstName: name.first,
              lastName: name.last,
              organizations: auth === "vantage facilitator" ? organizations : null,
            },
          });
        } else {
          res = await updateUser({
            variables: {
              id: admin.id,
              ...values,
              firstName: name.first,
              lastName: name.last,
              organizations: auth === "vantage facilitator" ? organizations : null,
            },
          });
        }

        if (res) {
          snack(`${isCreate ? "Created: " : "Updated"} admin: ${name.first} ${name.last}`);
          handleClose();

          if (isCreate) {
            requestFetch();
          } else if (admin.id === authCtx.id) {
            refreshUser(authCtx.id);
          }
        }
        setSubmitting(false);
      } catch (error) {
        snack(`Failed to ${isCreate ? "create: " : "update"} admin: ${name.first} ${name.last}`, "error");
      }
    },
    enableReinitialize: true,
  });

  const { values, errors, touched, dirty, handleSubmit, isSubmitting, getFieldProps, setFieldValue, resetForm } = formik;

  // const handleSubmit = async () => {
  //   if (isEdit) {
  //     const isUpdatingSelf = auth.id === form.id;

  //     try {
  //       const ok = await updateUser({
  //         variables: form,
  //       });

  //       if (ok.data.updateUser) {
  //         if (isUpdatingSelf) {
  //           snack("Updated admin. Please log in to view changes.");
  //           handleSignout();
  //         } else {
  //           snack("Updated admin");
  //           requestFetch();
  //           handleClose();
  //         }
  //       }
  //     } catch (err) {
  //       snack("Failed to update admin", "error");
  //     }
  //   } else {
  //     try {
  //       const { data } = await createAdmin({
  //         variables: form,
  //       });

  //       if (data.createAdmin) {
  //         snack(`Created user: ${form.firstName} ${form.lastName}`);
  //         requestFetch();
  //         handleClose();
  //       }
  //     } catch (err) {
  //       snack(err.message, "error");
  //     }
  //   }
  // };

  const handleResetPassword = async () => {
    if (isCreate) return;

    try {
      const res = await sendPasswordResetEmail({ variables: { _id: _.get(admin, "id") } });

      const pwResetUrl = _.get(res, "data.sendPasswordResetEmail");

      let showLink = false;
      if (navigator.clipboard && navigator.clipboard.writeText) {
        await navigator.clipboard.writeText(pwResetUrl);
      } else {
        showLink = true;
        console.warn("Clipboard API not supported");
      }

      snack(`Password reset link sent to currently configured email (${showLink ? pwResetUrl : "also copied to clipboard"})`);
    } catch (err) {
      snack(err.message, "error");
    }
  };

  useEffect(() => {
    if (!open) resetForm();
  }, [open]);

  return (
    <Dialog
      open={open}
      fullWidth
      onClose={(event, reason) => {
        if (reason !== "backdropClick") {
          handleClose();
        }
      }}
    >
      <DialogTitle>{isCreate ? "Create" : "Edit"} Admin</DialogTitle>
      <DialogContent>
        <FormikProvider>
          <TextField
            label="First Name"
            fullWidth
            variant="outlined"
            margin="normal"
            {...getFieldProps("name.first")}
            error={Boolean(_.get(touched, "name.first") && _.get(errors, "name.first"))}
            helperText={_.get(touched, "name.first") && _.get(errors, "name.first")}
          />
          <TextField
            label="Last Name"
            fullWidth
            variant="outlined"
            margin="normal"
            {...getFieldProps("name.last")}
            error={Boolean(_.get(touched, "name.last") && _.get(errors, "name.last"))}
            helperText={_.get(touched, "name.last") && _.get(errors, "name.last")}
          />
          <TextField
            label="Email"
            fullWidth
            variant="outlined"
            margin="normal"
            {...getFieldProps("email")}
            error={Boolean(touched.email && errors.email)}
            helperText={touched.email && errors.email}
          />
          <TextField
            select
            fullWidth
            label="Authorization"
            variant="outlined"
            margin="normal"
            {...getFieldProps("auth")}
            InputLabelProps={{
              shrink: !_.isEmpty(values.auth),
            }}
            error={Boolean(touched.auth && errors.auth)}
            helperText={touched.auth && errors.auth}
          >
            <MenuItem value="vantage admin">Vantage Admin</MenuItem>
            <MenuItem value="vantage facilitator">Vantage Facilitator</MenuItem>
          </TextField>
          <Collapse in={values.auth === "vantage facilitator"}>
            <TextField
              select
              fullWidth
              label="Organizations"
              variant="outlined"
              margin="normal"
              {...getFieldProps("organizations")}
              value={values.organizations || []} // needed because of "multiple" prop and the field being nullable
              SelectProps={{
                multiple: true,
              }}
              InputLabelProps={{
                shrink: !_.isEmpty(values.organizations),
              }}
              error={Boolean(touched.organizations && errors.organizations)}
              helperText={touched.organizations && errors.organizations}
            >
              {organizations &&
                organizations.map(({ name, id }) => {
                  return (
                    <MenuItem value={id} key={id}>
                      {name}
                    </MenuItem>
                  );
                })}
            </TextField>
          </Collapse>
        </FormikProvider>
      </DialogContent>
      <DialogActions>
        {isVantageAdmin(authCtx) && !isCreate && (
          <Button style={{ marginRight: "auto" }} onClick={handleResetPassword} disabled={resetPwLoading}>
            Send Reset Password Link
          </Button>
        )}
        <Button onClick={handleClose}>Cancel</Button>
        <Button variant="contained" color="primary" onClick={handleSubmit} disabled={isSubmitting || !dirty}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddEditAdminDialog;

const UPDATE_USER = gql`
  mutation ($id: ID!, $firstName: String, $lastName: String, $email: String, $auth: String, $organizations: [ID!]) {
    updateUser(id: $id, firstName: $firstName, lastName: $lastName, email: $email, auth: $auth, organizations: $organizations) {
      id
      email
      name {
        first
        last
      }
      auth
      organizations
    }
  }
`;

const CREATE_ADMIN = gql`
  mutation ($firstName: String!, $lastName: String!, $email: String!, $auth: String!, $organizations: [ID!]) {
    createAdmin(firstName: $firstName, lastName: $lastName, email: $email, auth: $auth, organizations: $organizations) {
      id
      email
      name {
        first
        last
      }
      auth
      organizations
    }
  }
`;

const SEND_PASSWORD_RESET_EMAIL = gql`
  mutation ($email: String, $_id: ID) {
    sendPasswordResetEmail(email: $email, _id: $_id)
  }
`;
