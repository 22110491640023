import { MenuItem, Typography, useTheme } from "@material-ui/core";
import Menu from "../../components/Menu/Menu";
import React from "react";
import styles from "./SelectCorpYear.module.scss";
import { setYear, year as yearUtil, fullDate, fiscalYearStart } from "../../utils/dates";
import _ from "lodash";

const SelectCorpYear = ({ corporatePlans, fiscalYear, corpForSelectedYear, setCorpForSelectedYear, outlined = false, small }) => {
  return (
    <Menu
      button={
        <div className={styles.button}>
          <Typography variant="subtitle1" className={styles.label}>
            Year:{" "}
            {corpForSelectedYear.year &&
              `${fullDate(fiscalYearStart(setYear(fiscalYear, yearUtil(corpForSelectedYear.year))))} - ${fullDate(
                setYear(fiscalYear, yearUtil(corpForSelectedYear.year))
              )}`}
          </Typography>
        </div>
      }
      buttonProps={{ variant: outlined ? "outlined" : "text", size: small ? "small" : "medium" }}
      icon="arrow"
      menuProps={{
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "left",
        },
        transformOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      }}
    >
      {_.sortBy(corporatePlans, (p) => parseInt(-p.year)).map((plan) => (
        <MenuItem
          key={plan.id}
          onClick={() => setCorpForSelectedYear(plan)}
          disabled={plan.id === corpForSelectedYear.id}
          selected={plan.id === corpForSelectedYear.id}
        >
          {fullDate(fiscalYearStart(setYear(fiscalYear, yearUtil(plan.year))))} - {fullDate(setYear(fiscalYear, yearUtil(plan.year)))}
        </MenuItem>
      ))}
    </Menu>
  );
};

export default SelectCorpYear;
