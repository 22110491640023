import React, { useEffect, useState } from "react";
import styles from "./LLMDialogs.module.scss";
import _ from "lodash";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  useTheme,
  Tooltip,
  Typography,
  Menu,
  MenuItem,
  FormControlLabel,
  Switch,
  Collapse,
  FormGroup,
  Checkbox,
} from "@material-ui/core";
import * as Yup from "yup";
import { useFormik, FormikProvider } from "formik";
import { LLM_SYSTEM_OBJECTS_DICT, LLM_TYPES } from "../../utils/const";
import OutlinedSection from "../OutlinedSection/OutlinedSection";
import { Info } from "@material-ui/icons";
import useDidUpdateEffect from "../../hooks/useDidUpdateEffect";

const LLMSettingsDialog = ({ open, onClose, type, title, purpose, llmSettingsArr = [], handleSave }) => {
  const theme = useTheme();

  const [anchorEl, setAnchorEl] = useState(null);
  const [llmSettingsType, setLlmSettingsType] = useState(type);

  const schema = Yup.object().shape({
    type: Yup.string().required("Type is required"),
    globalSystemDescription: Yup.string().required("Global system description is required"),
    globalUserPrompt: Yup.string().required("Global user prompt is required"),
    systemDescription: Yup.string(),
    userPrompt: Yup.string(),
    useGlobal: Yup.object({
      systemDescription: Yup.boolean(),
      userPrompt: Yup.boolean(),
    }),
    systemObjects: Yup.array().of(Yup.string()),
  });

  const getInitialValues = (llmSettingsArr, type) => {
    const globalSettings = _.find(llmSettingsArr, ["type", "global"]);
    const typeSettings = _.find(llmSettingsArr, ["type", type]);
    const useGlobal = _.get(typeSettings, "useGlobal", { systemDescription: true, userPrompt: true });

    const initVals = {
      type,
      globalSystemDescription: _.get(globalSettings, "systemDescription", ""),
      globalUserPrompt: _.get(globalSettings, "userPrompt", ""),
      systemDescription: _.get(typeSettings, "systemDescription", ""),
      userPrompt: _.get(typeSettings, "userPrompt", ""),
      useGlobal: _.omit(useGlobal, "__typename"),
      systemObjects: _.get(typeSettings, "systemObjects", []),
    };

    return initVals;
  };

  const formik = useFormik({
    initialValues: getInitialValues(llmSettingsArr, llmSettingsType),
    validationSchema: schema,
    onSubmit: async (values, { setSubmitting }) => {
      handleSave(values);
      setSubmitting(false);
    },
    enableReinitialize: true,
  });

  const { values, touched, errors, dirty, handleSubmit, isSubmitting, getFieldProps, setFieldValue, resetForm } = formik;

  const handleOpenTypeMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseTypeMenu = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (!open) {
      resetForm();
    }
  }, [open]);

  useDidUpdateEffect(() => {
    setLlmSettingsType(values.type);
  }, [values.type]);

  const userPromptMoreInfo = {
    brainstorm: `Information about the organization as well as it's ${values.type} precedes this prompt. Use {{type}} as the placeholder for the document type.`,
    rephrase: "The current text to be rephrased follows this prompt. Use {{type}} as the placeholder for the document type.",
  }[purpose];

  return (
    <>
      <Dialog
        open={open}
        onClose={(event, reason) => {
          if (reason !== "backdropClick") {
            onClose();
          }
        }}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>
          <div style={{ display: "flex", justifyContent: "space-between", alignContent: "center" }}>
            <Typography style={{ display: "flex", alignItems: "center" }} variant="h6">
              {title}
            </Typography>
            <Tooltip title={dirty ? "Please save changes first before swapping types" : ""} placement="left">
              <div>
                <Button color="primary" variant="outlined" onClick={(e) => handleOpenTypeMenu(e)} disabled={dirty}>
                  Type: {_.startCase(values.type)}
                </Button>
              </div>
            </Tooltip>
          </div>
        </DialogTitle>
        <DialogContent>
          <FormikProvider value={formik}>
            {purpose === "brainstorm" && (
              <OutlinedSection
                title={
                  <div className={styles.flex} style={{ gap: theme.spacing(0.5) }}>
                    <span>System Objects</span>
                    <Tooltip
                      title={`The related data that will be included as of the part of ${_.lowerCase(
                        values.type
                      )} to be provided to the LLM`}
                      placement="right"
                    >
                      <Info color="primary" />
                    </Tooltip>
                  </div>
                }
              >
                <FormGroup row>
                  {_.get(LLM_SYSTEM_OBJECTS_DICT, values.type, []).map((option) => {
                    const optionValue = _.toLower(option);
                    return (
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={_.get(values, "systemObjects", []).includes(optionValue)}
                            onChange={(e) => {
                              const checked = e.target.checked;
                              const currSystemObjs = _.clone(values.systemObjects);

                              if (checked) {
                                if (!_.includes(currSystemObjs, optionValue)) {
                                  currSystemObjs.push(optionValue);
                                }
                              } else {
                                _.pull(currSystemObjs, optionValue);
                              }
                              setFieldValue("systemObjects", currSystemObjs);
                            }}
                          />
                        }
                        label={_.startCase(option)}
                      />
                    );
                  })}
                </FormGroup>
              </OutlinedSection>
            )}
            <OutlinedSection
              title={
                <div className={styles.flex} style={{ gap: theme.spacing(0.5) }}>
                  <span>System Description</span>
                  <Tooltip
                    title={`Describes what the model is supposed to do and how it should generally behave and respond. Use {{type}} as the placeholder for the document type.`}
                    placement="right"
                  >
                    <Info color="primary" />
                  </Tooltip>
                </div>
              }
            >
              <FormControlLabel
                control={
                  <Switch
                    size="small"
                    name="enableFormula"
                    checked={_.get(values, "useGlobal.systemDescription")}
                    onChange={(e) => setFieldValue("useGlobal.systemDescription", e.target.checked)}
                  />
                }
                label="Use Global"
                style={{ marginLeft: 0 }}
              />
              <Collapse timeout="auto" in={_.get(values, "useGlobal.systemDescription")}>
                <TextField
                  label="Global System Description"
                  {...getFieldProps("globalSystemDescription")}
                  fullWidth
                  multiline
                  variant="outlined"
                  margin="normal"
                  InputLabelProps={{ shrink: true }}
                  error={Boolean(touched.globalSystemDescription && errors.globalSystemDescription)}
                  helperText={touched.globalSystemDescription && errors.globalSystemDescription}
                />
              </Collapse>
              <TextField
                label={`${_.startCase(values.type)} System Description`}
                {...getFieldProps("systemDescription")}
                fullWidth
                multiline
                variant="outlined"
                margin="normal"
                InputLabelProps={{ shrink: true }}
              />
            </OutlinedSection>
            <OutlinedSection
              title={
                <div className={styles.flex} style={{ gap: theme.spacing(0.5) }}>
                  <span>User Prompt</span>
                  <Tooltip title={userPromptMoreInfo} placement="right">
                    <Info color="primary" />
                  </Tooltip>
                </div>
              }
            >
              <FormControlLabel
                control={
                  <Switch
                    size="small"
                    name="enableFormula"
                    checked={_.get(values, "useGlobal.userPrompt")}
                    onChange={(e) => setFieldValue("useGlobal.userPrompt", e.target.checked)}
                  />
                }
                label="Use Global"
                style={{ marginLeft: 0 }}
              />
              <Collapse timeout="auto" in={_.get(values, "useGlobal.userPrompt")}>
                <TextField
                  label="Global User Prompt"
                  {...getFieldProps("globalUserPrompt")}
                  fullWidth
                  multiline
                  variant="outlined"
                  margin="normal"
                  InputLabelProps={{ shrink: true }}
                  error={Boolean(touched.globalUserPrompt && errors.globalUserPrompt)}
                  helperText={touched.globalUserPrompt && errors.globalUserPrompt}
                />
              </Collapse>
              <TextField
                label={`${_.startCase(values.type)} User Prompt`}
                {...getFieldProps("userPrompt")}
                fullWidth
                multiline
                variant="outlined"
                margin="normal"
                InputLabelProps={{ shrink: true }}
              />
            </OutlinedSection>
          </FormikProvider>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Close</Button>
          <Button onClick={handleSubmit} color="primary" variant="contained" disabled={isSubmitting || !dirty}>
            Save
          </Button>
        </DialogActions>
      </Dialog>
      <Menu
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        open={Boolean(anchorEl)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        onClick={handleCloseTypeMenu}
        onClose={handleCloseTypeMenu}
      >
        {_.without(LLM_TYPES, values.type)
          .sort()
          .map((type) => {
            return (
              <MenuItem key={type} onClick={() => setFieldValue("type", type)}>
                {_.startCase(type)}
              </MenuItem>
            );
          })}
      </Menu>
    </>
  );
};

export default LLMSettingsDialog;
