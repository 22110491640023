import React, { useEffect, useState, useMemo, useContext } from "react";
import styles from "./Dashboard.module.scss";
import _ from "lodash";
import gql from "graphql-tag";
import { getCurrentQuarter } from "../../utils/dates";
import { useDepartmentFilter } from "../../context/departmentFilterContext";
import { useMutation, useQuery } from "@apollo/client";
import { SnackbarContext } from "../../context/snackbarContext";
import { indexToLetter } from "../../utils/misc";
import useCorpPlan from "../../hooks/useCorpPlan";

import { Grid, MenuItem, TextField, Typography, useTheme } from "@material-ui/core";
import Icon from "@mdi/react";
import { mdiRhombusOutline, mdiRhombusSplitOutline, mdiCheckboxMarkedOutline, mdiAlertDecagramOutline } from "@mdi/js";

import KanbanBoard from "../../components/KanbanBoard/KanbanBoard";
import SelectCorpYear from "../../components/SelectCorpYear/SelectCorpYear";
import Menu from "../../components/Menu/Menu";

const COLUMNS = [
  { id: "on hold", name: "On Hold" },
  { id: "not started", name: "Not Started" },
  { id: "in progress", name: "In Progress" },
  { id: "roadblocked", name: "Roadblocked" },
  { id: "completed", name: "Completed" },
];

const ITEM_FIELD_KEY_DICT = {
  rock: {
    position: "kanbanPosition",
  },
  todo: {
    position: "kanbanPosition",
  },
  issue: {
    position: "kanbanPosition",
  },
  successCriteria: {
    position: "kanbanPosition",
  },
};

const ITEM_FIELD_VAL_DICT = {
  rock: {
    status: {
      "on track": "in progress",
      "off track": "in progress",
      complete: "completed",
    },
  },
};

const Kanban = ({ org, filterUser }) => {
  const theme = useTheme();

  const fiscalYear = org.fiscalYear;
  const { corpForSelectedYear, setCorpForSelectedYear, corpPlans } = useCorpPlan({ orgId: org.id, fiscalYear });
  const { departmentFilter } = useDepartmentFilter();

  // const [selectedQuarter, setSelectedQuarter] = useState(getCurrentQuarter(fiscalYear));

  const {
    data,
    loading: queryLoading,
    refetch,
  } = useQuery(GET_USERS_AND_PLANS, {
    variables: { organization: org.id, oneYearCorpPlan: _.get(corpForSelectedYear, "id", null) },
  });

  const filters = useMemo(() => {
    return {
      organization: org.id,
      users: [...(filterUser ? [filterUser.id] : [])],
      sharedPlanId: departmentFilter.sharedPlanId,
      oneYearCorpPlan: corpForSelectedYear.id,
      // quarter: selectedQuarter,
    };
  }, [
    org,
    filterUser,
    departmentFilter,
    corpForSelectedYear,
    // selectedQuarter
  ]);

  const usersById = useMemo(() => {
    if (!data) return {};

    const users = _.get(data, "users", []);
    return _.keyBy(users, "id");
  }, [data]);

  const plansById = useMemo(() => {
    if (!data) return {};

    const plans = _.get(data, "plans", []);
    return _.keyBy(plans, "id");
  }, [data]);

  const renderItemDisplayId = ({ type, dispId }) => {
    let ret;
    switch (type) {
      case "issue":
        ret = (
          <>
            <Icon path={mdiAlertDecagramOutline} size={0.75} />
            {`I${dispId}`}
          </>
        );
        break;
      case "todo":
        ret = (
          <>
            <Icon path={mdiCheckboxMarkedOutline} size={0.75} />
            {`T${dispId}`}
          </>
        );
        break;
      case "rock":
        ret = (
          <>
            <Icon path={mdiRhombusOutline} size={0.75} />
            {`R${dispId}`}
          </>
        );
        break;
      case "successCriteria":
        const [rockNum, scNum] = dispId.split("-");
        ret = (
          <>
            <Icon path={mdiRhombusSplitOutline} size={0.75} />
            {`R${rockNum}${indexToLetter(parseInt(scNum))}`}
          </>
        );
        break;
      default:
    }

    return <div style={{ display: "flex", alignItems: "center", gap: theme.spacing(0.5) }}>{ret}</div>;
  };

  return (
    <div
      className={styles.flexCols}
      style={{
        height: "100%", // comment out if you want to have the scroll on this div instead of each kanban column
        gap: theme.spacing(2),
      }}
    >
      <div style={{ padding: `calc(${theme.spacing(3)}px - 8px) ${theme.spacing(3)}px 0` }}>
        <div style={{ display: "flex", alignItems: "center", gap: theme.spacing(1) }}>
          <SelectCorpYear
            outlined
            small
            corporatePlans={corpPlans}
            fiscalYear={fiscalYear}
            corpForSelectedYear={corpForSelectedYear}
            setCorpForSelectedYear={setCorpForSelectedYear}
          />
          {/* <Menu
            button={
              <Typography variant="subtitle1" className={styles.kanbanBtn}>
                Quarter: {selectedQuarter}
              </Typography>
            }
            icon="arrow"
            buttonProps={{ variant: "outlined", size: "small" }}
            menuProps={{
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "left",
              },
              transformOrigin: {
                vertical: "top",
                horizontal: "left",
              },
            }}
          >
            {[1, 2, 3, 4].map((quarter, idx) => {
              return (
                <MenuItem key={idx} onClick={() => setSelectedQuarter(quarter)}>
                  Q{quarter}
                </MenuItem>
              );
            })}
          </Menu> */}
        </div>
      </div>
      <div
        style={{
          flex: 1,
          overflow: "hidden",
        }}
      >
        <div
          style={{
            height: "100%",
            overflowX: "auto",
            margin: `0 ${theme.spacing(3)}px`,
            paddingBottom: theme.spacing(3),
          }}
        >
          <KanbanBoard
            columns={COLUMNS}
            filters={filters}
            itemFieldKeyDict={ITEM_FIELD_KEY_DICT}
            itemFieldValDict={ITEM_FIELD_VAL_DICT}
            columnDataQuery={GET_KANBAN_STATUS_DATA}
            updateItemMutation={MOVE_KANBAN_CARD}
            queryName="kanbanStatusData"
            mutationName="moveKanbanCard"
            cardDispIdFunc={renderItemDisplayId}
            usersDict={usersById}
            plansDict={plansById}
          />
        </div>
      </div>
    </div>
  );
};

export default Kanban;

const GET_USERS_AND_PLANS = gql`
  query AddObjectiveDialog_GetUsersObjectivesPlans($organization: ID!, $oneYearCorpPlan: ID) {
    users(organization: $organization) {
      id
      name {
        first
        last
      }
      profilePicture
    }

    plans(organization: $organization, category: "1 year", oneYearCorpPlan: $oneYearCorpPlan) {
      id
      departmentName
      sharedPlanId
      color
      shortName
    }
  }
`;

const MOVE_KANBAN_CARD = gql`
  mutation moveKanbanCardMutation($id: ID!, $type: String!, $status: String, $targetPosition: Int!) {
    moveKanbanCard(id: $id, type: $type, status: $status, targetPosition: $targetPosition) {
      id
      type
      value
      status
      kanbanPosition
      dispId
      targetDate
      plan
      user
    }
  }
`;

const GET_KANBAN_STATUS_DATA = gql`
  query kanbanStatusDataQuery($organization: ID!, $users: [ID!], $status: String!, $oneYearCorpPlan: ID, $sharedPlanId: ID, $cursor: Int) {
    kanbanStatusData(
      organization: $organization
      users: $users
      status: $status
      oneYearCorpPlan: $oneYearCorpPlan
      sharedPlanId: $sharedPlanId
      cursor: $cursor
    ) {
      items {
        id
        value
        type
        status
        kanbanPosition
        dispId
        targetDate
        plan
        user
      }
      nextCursor
      hasMore
      totalItems
    }
  }
`;
