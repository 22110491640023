import { format, add, differenceInYears, isAfter, getYear } from "date-fns";
import _ from "lodash";
import { evaluate } from "mathjs";
import { quarterDates, addToDate, formatAs } from "./dates";
import { DEFAULT_MEETING_ACTIONS } from "./const";

export const getContrastText = (hex) => {
  let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  if (!result) {
    return "#FFFFFF";
  }

  let red = parseInt(result[1], 16);
  let green = parseInt(result[2], 16);
  let blue = parseInt(result[3], 16);

  if (red * 0.299 + green * 0.587 + blue * 0.114 > 186) {
    return "#000000";
  } else {
    return "#FFFFFF";
  }
};

export const getPlansByReferenceModel = (referenceModel, item) => {
  let plans = [];
  if (referenceModel === "SuccessCriteria") {
    plans = plans.concat(_.get(item, "rock.plan", []));
  } else if (referenceModel === "WeeklyTarget") {
    plans = plans.concat(_.get(item, "plans", []));
  } else {
    plans = _.get(item, "plan") ? [item.plan] : [];
  }
  return plans;
};

export const getDeptShortName = (departmentName, shortName) => {
  return !_.isNil(shortName) && !_.isEmpty(shortName) ? shortName.toUpperCase() : (departmentName || "CORP").slice(0, 4).toUpperCase();
};

export const compareDeptNames = (plansA, plansB) => {
  const combinedNameForA = plansA.map((plan) => getDeptShortName(plan.departmentName, plan.shortName)).join("");
  const combinedNameForB = plansB.map((plan) => getDeptShortName(plan.departmentName, plan.shortName)).join("");
  return combinedNameForA > combinedNameForB ? 1 : -1;
};

export function convertToWeeklyTargetsReferenceMap(weeklyTargets) {
  let referenceMap = {};
  weeklyTargets.forEach((weeklyTarget) => {
    const { enableFormula, id, measurables } = weeklyTarget;
    if (enableFormula !== true) {
      let dateMap = {};
      measurables.forEach((obj) => {
        const { value } = obj;
        const formattedWeek = format(add(new Date(obj.week), { hours: 12 }), "yyyyMMdd");
        dateMap[formattedWeek] = value;
      });
      referenceMap[id] = dateMap;
    }
  });
  return referenceMap;
}

export function convertToMetricsReferenceMap(metrics) {
  let referenceMap = {};

  metrics.forEach((metric) => {
    const { enableFormula, id, measurables } = metric;
    if (enableFormula !== true) {
      referenceMap[id] = measurables;
    }
  });
  return referenceMap;
}

export const removeTypenameDeep = (obj = {}) => {
  let newObj = _.omit(obj, "__typename");
  Object.keys(newObj).forEach((field) => {
    if (typeof newObj[field] === "object") {
      newObj[field] = removeTypenameDeep(newObj[field]);
    }
  });
  return newObj;
};

// for weekly target value
export const parseWTValueUsingFormula = ({ formula = "", formulaScope = [], formattedWeek, referenceMap }) => {
  const formulaScopeValues = formulaScope.map((variable) => {
    if (variable.varType === "number") {
      return Number(variable.value);
    } else if (variable.varType === "reference") {
      const id = variable.value;
      const referenceValue = _.get(referenceMap, `${id}.${formattedWeek}`);
      return _.toNumber(referenceValue);
    } else {
      return undefined;
    }
  });
  let formattedFormulaScope = {};
  formulaScopeValues.forEach((value, idx) => {
    formattedFormulaScope[`v${idx}`] = value;
  });

  let result = NaN;
  try {
    const evaluatedValue = evaluate(formula, formattedFormulaScope);
    result = _.round(evaluatedValue, 3);
  } catch (e) {
    // if the evaluate function throw an error, ignore for now and return NaN
  }

  return result;
};

export const parseMetricValueUsingFormula = ({ formula = "", formulaScope = [], index, valueIndex, referenceMap }) => {
  const formulaScopeValues = formulaScope.map((variable) => {
    if (variable.varType === "number") {
      return Number(variable.value);
    } else if (variable.varType === "reference") {
      const id = variable.value;
      const referenceValue = _.get(referenceMap, `${id}.${index}.${valueIndex}.value`);
      return _.toNumber(referenceValue);
    } else {
      return undefined;
    }
  });
  let formattedFormulaScope = {};
  formulaScopeValues.forEach((value, idx) => {
    formattedFormulaScope[`v${idx}`] = value;
  });

  let result = NaN;
  try {
    const evaluatedValue = evaluate(formula, formattedFormulaScope);
    result = _.round(evaluatedValue, 3);
  } catch (e) {
    // if the evaluate function throw an error, ignore for now and return NaN
  }

  return result;
};

export const calcQuarterMetricTotal = (measurables, valTypeIdx) => {
  return measurables.reduce((sum, arr) => {
    let numString = arr[valTypeIdx].value;
    return arr[0].value !== "Total" ? sum + Number(numString.replace(/,/g, "")) : sum;
  }, 0);
};

export const getQuarterMetricTotal = (quarterMeasurables, calculateTotal) => {
  let total;
  if (quarterMeasurables.length === 5 && calculateTotal) {
    total = _.get(quarterMeasurables, ["4", "2", "value"], 0);
  } else {
    // converted to string because the return value of the if condition is a string (i.e. to have a consistent return type)
    total = calcQuarterMetricTotal(quarterMeasurables, 2).toString();
  }

  return total;
};

export const mergeCustomizer = (objValue, srcValue) => {
  if (_.isArray(objValue)) {
    return objValue.concat(srcValue);
  }
};

export const getQuarters = (fiscalYear, quarter) => {
  const [start, end] = quarterDates(addToDate(fiscalYear, { days: 1 }), quarter);
  return `(${formatAs(start, "MMM d")} - ${formatAs(end, "MMM d")})`;
};

export const getCoreDisplayName = (name) => {
  let newName = name;
  switch (name) {
    case "differentiation":
      newName = "differentiator";
      break;
    case "process":
      newName = "journey";
      break;
    default:
      newName = name;
  }
  return newName;
};

export const getPriorityValue = (priority) => {
  let value = 3;
  switch (priority) {
    case "high":
      value = 1;
      break;
    case "medium":
      value = 2;
      break;
    default:
      value = 3;
  }
  return value;
};

export const getMeetingStepsFromType = (type) => {
  const { checkIn, scorecard, rocks, textBlock, toDos, issues, conclude } = DEFAULT_MEETING_ACTIONS;
  let steps = [];

  switch (type) {
    case "hem":
    case "custom":
      steps = [checkIn, scorecard, rocks, toDos, issues, conclude];
  }

  return steps;
};

export const getCurrentThreeYearPlan = (threeYearPlans, planYear) => {
  // old behavior when there is only a single three year plan
  if (threeYearPlans.length === 1) {
    return threeYearPlans[0];
  }

  return threeYearPlans.find((plan) => {
    const { targetDate } = plan;
    const targetDateYearInt = getYear(parseInt(targetDate));
    const planYearInt = getYear(parseInt(planYear));
    const yearDiff = targetDateYearInt - planYearInt;
    if (yearDiff < 3 && yearDiff >= 0) {
      return true;
    } else {
      return false;
    }
  });
};

export const notesSectionIsNotEmpty = (sectionObj) => {
  return _.get(sectionObj, "children", []).length > 1 || _.get(sectionObj, ["children", "0", "text"], "") !== "";
};

export const removeFormattedNum = (value) => {
  return value.replace(/,/g, "");
};

export const recursiveMergeCustomizer = (srcVal) => {
  if (_.isArray(srcVal)) {
    // Recursively apply the customizer to each element of the array
    return _.map(srcVal, recursiveMergeCustomizer);
  } else if (_.isObject(srcVal)) {
    // Return the 'id' if it exists, otherwise recursively apply the customizer to each field
    const id = _.get(srcVal, "id");
    return id ? id : _.mapValues(srcVal, recursiveMergeCustomizer);
  }
  // Return the primitive value as is
  return srcVal;
};

export const recursiveMergeCustomizerWithSkipRetain = ({ srcVal, fieldName, skipFields = [], retainFields = [] }) => {
  // If the field name is in the skip list, return the value from data directly
  if (skipFields.includes(fieldName)) {
    return srcVal;
  }

  // If the field name is in the retain list, keep the value from init vals
  if (retainFields.includes(fieldName)) {
    return undefined; // Returning `undefined` tells _.mergeWith to retain the initVal
  }

  if (_.isArray(srcVal)) {
    // Recursively apply the customizer to each element of the array
    return _.map(srcVal, (val) => recursiveMergeCustomizerWithSkipRetain({ srcVal: val, fieldName: null, skipFields, retainFields }));
  } else if (_.isObject(srcVal)) {
    // Return the 'id' if it exists, otherwise recursively apply the customizer to each field
    const id = _.get(srcVal, "id");
    if (id) {
      return id;
    }
    return _.mapValues(srcVal, (val, key) =>
      recursiveMergeCustomizerWithSkipRetain({ srcVal: val, fieldName: key, skipFields, retainFields })
    );
  }

  // Return the primitive value as is
  return srcVal;
};

export const toTitleCase = (str) => {
  return _.startCase(_.toLower(str)).trim();
};

export const indexToLetter = (index) => {
  return String.fromCharCode(65 + index);
};

export const getUsersByPlan = (users = [], plans = []) => {
  const planIds = plans.map((plan) => _.get(plan, "id", plan));

  const dict = _(users)
    .flatMap((user) =>
      _.get(user, "plan", []).map((userPlan) => ({
        planId: _.get(userPlan, "id", userPlan),
        user: _.get(user, "id", user),
      }))
    )
    .filter((item) => planIds.includes(item.planId))
    .groupBy("planId")
    .mapValues((group) => group.map((item) => item.user))
    .value();

  return dict;
};
