import React, { useState, useRef, useContext, useMemo } from "react";
import _ from "lodash";
import styles from "./SelectUsers.module.scss";
import { MenuItem, FormControlLabel, Checkbox, Avatar, TextField, Typography, useTheme } from "@material-ui/core";
import { useUser } from "../../context/userContext";

const NewSelectUsers = ({
  name,
  label,
  users = [],
  multiple,
  helperText,
  plan,
  allPlans = [],
  disabled = false,
  handleResetField = () => {},
  formik,
}) => {
  const { values: formikValues, errors, touched, getFieldProps } = formik;

  const theme = useTheme();
  const { user: loggedInUser } = useUser();

  const isTouched = _.get(touched, name);
  const error = _.get(errors, name);
  // const userValue = _.get(formikValues, name);

  const hasError = Boolean(isTouched && error);
  const helperTxt = hasError ? error : helperText;

  const sharedPlanIdDict = useMemo(() => {
    return allPlans.reduce((prev, curr) => {
      return { ...prev, [curr.id]: curr.sharedPlanId };
    }, {});
  }, [allPlans]);

  const usersToShow = useMemo(() => {
    if (_.isEmpty(loggedInUser)) return [];

    const planArr = _.isArray(plan) ? plan : _.isNil(plan) ? [] : [plan];
    const sharedPlanIdArr = planArr.map((id) => sharedPlanIdDict[id]);

    // If there exists selected plan(s) then only return users that belong to said plan(s), otherwise return the logged-in user
    const assignableUsers = !_.isEmpty(planArr)
      ? users.filter((user) => user.plan.some((userPlan) => sharedPlanIdArr.includes(userPlan?.sharedPlanId)))
      : [loggedInUser];

    // /* The following checks if the currently selected user(s) are valid based on the selected department(s).
    //    In case of multiple selectable users, ALL selected users must be an assignable user from the selected department(s) otherwise validation fails */
    // const selectedUserIsValid = _.isArray(userValue)
    //   ? userValue.every((selectedUser) => assignableUsers.some((assignableUser) => assignableUser.id === selectedUser))
    //   : assignableUsers.some((assignableUser) => assignableUser.id === userValue);

    // // If there there exists selected user(s) which do not belong to the selected department(s) then reset this user dropdown to an empty selection
    // if (!hasError && !selectedUserIsValid) {
    //   console.log("RESET USER LOG", { hasError, selectedUserIsValid, assignableUsers, userValue, plan });
    //   handleResetField();
    // }

    return assignableUsers;
  }, [
    users,
    plan,
    loggedInUser,
    sharedPlanIdDict,
    // hasError,
    // userValue
  ]);

  return (
    <TextField
      select
      fullWidth
      label={label || "Person Accountable"}
      variant="outlined"
      margin="normal"
      style={{ opacity: disabled ? 0.5 : 1 }}
      {...getFieldProps(name)}
      SelectProps={{
        multiple,
        ...(multiple
          ? {
              renderValue: (selected) => `${selected.length} User(s) Selected`,
            }
          : {}),
      }}
      error={hasError}
      helperText={helperTxt}
      disabled={disabled}
    >
      {!_.isEmpty(usersToShow) ? (
        usersToShow.map(({ name: username, profilePicture, id }) => {
          return (
            <MenuItem key={id} value={id}>
              <div className={styles.item} style={{ gap: theme.spacing(1) }}>
                <Avatar className={styles.avatar} src={profilePicture}>
                  {username.first[0]}
                  {username.last[0]}
                </Avatar>
                <Typography>{`${username.first} ${username.last}`}</Typography>
              </div>
            </MenuItem>
          );
        })
      ) : (
        <MenuItem value={""}>No users found for selected department(s)</MenuItem>
      )}
    </TextField>
  );
};

export default NewSelectUsers;
