import React, { useEffect, useState } from "react";
import styles from "./Dashboard.module.scss";
import _ from "lodash";
import gql from "graphql-tag";
import { useQuery } from "@apollo/client";

import { Container, useTheme, useMediaQuery, AppBar, Tabs, Tab, Box, Typography, Divider, TextField, Chip, Grid } from "@material-ui/core";

import Overview from "./Overview";
import Calendar from "./Calendar";
import { Autocomplete } from "@material-ui/lab";
import { useAuth } from "../../context/authContext";
import Kanban from "./Kanban";

const TABS = ["Overview", "Kanban", "Calendar"];

const Dashboard = ({ params, org }) => {
  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up("sm"));

  const { auth } = useAuth();
  const [tabIndex, setTabIndex] = useState(1);
  const [filterUser, setFilterUser] = useState();

  const { data, loading, refetch } = useQuery(GET_DATA, {
    variables: {
      organization: org.id,
    },
    fetchPolicy: "network-only",
  });

  const users = _.get(data, "users", []);

  useEffect(() => {
    if (users) {
      const myUser = _.find(users, ["id", auth.id]);
      if (myUser) {
        setFilterUser(myUser);
      }
    }
  }, [users]);

  return (
    <div
      className={styles.flexCols}
      style={{
        flex: 1,
        marginTop: -30,
        marginBottom: -30,
        overflowY: "hidden",
      }}
    >
      <AppBar position="sticky" elevation={0} className={styles.dashBar}>
        <Grid container alignItems="center" spacing={1}>
          <Grid item xs={12} sm={9}>
            <Tabs
              value={tabIndex}
              onChange={(e, val) => setTabIndex(val)}
              indicatorColor="primary"
              variant="fullWidth"
              style={{ marginLeft: theme.spacing(1), marginRight: smUp ? 0 : theme.spacing(1) }}
            >
              {TABS.map((tab) => (
                <Tab key={tab} label={tab} />
              ))}
            </Tabs>
          </Grid>
          <Grid item xs={12} sm={3}>
            <Box>
              <Autocomplete
                options={users}
                value={filterUser}
                style={{ margin: theme.spacing(1), marginTop: smUp ? theme.spacing(1) : 0 }}
                onChange={(e, val) => setFilterUser(val)}
                getOptionLabel={(user) => {
                  const name = _.get(user, "name", {});
                  return `${name.first} ${name.last}`;
                }}
                getOptionSelected={(option, val) => _.get(option, "id", 1) === _.get(val, "id", 2)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    placeholder="Please select user"
                    margin="dense"
                    style={{ backgroundColor: "white", borderRadius: 4, margin: 0 }}
                    // inputProps={{ ...params.inputProps }}
                  />
                )}
              />
            </Box>
          </Grid>
        </Grid>
      </AppBar>
      <div
        style={{
          overflowY: "auto",
          boxSizing: "border-box",
          flex: 1,
        }}
      >
        {TABS[tabIndex] === "Overview" && <Overview org={org} filterUser={filterUser} />}
        {TABS[tabIndex] === "Kanban" && <Kanban org={org} filterUser={filterUser} />}
        {TABS[tabIndex] === "Calendar" && <Calendar org={org} />}
      </div>
    </div>
  );
};

export default Dashboard;

const GET_DATA = gql`
  query ($organization: ID!) {
    users(organization: $organization) {
      id
      name {
        first
        last
      }
      plan {
        id
        departmentName
        closed
        year
      }
      profilePicture
      position
      email
      auth
    }

    usersCount(organization: $organization)
  }
`;
