import React, { useState } from "react";
import { useTheme, IconButton, Button, Menu as MaterialUiMenu } from "@material-ui/core";
import Icon from "@mdi/react";
import { mdiDotsHorizontal, mdiChevronDown, mdiPencil, mdiCrown, mdiChevronUp } from "@mdi/js";

const Menu = ({ icon, button, buttonProps, menuProps, color = "rgba(0, 0, 0, 0.54)", children }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const isOpen = Boolean(anchorEl);

  let path;
  switch (icon) {
    case "arrow":
      path = isOpen ? mdiChevronUp : mdiChevronDown;
      break;
    case "pencil":
      path = mdiPencil;
      break;
    case "crown":
      path = mdiCrown;
      break;
    default:
      path = mdiDotsHorizontal;
      break;
  }

  return (
    <>
      {button ? (
        <Button onClick={handleClick} {...buttonProps}>
          {button}
          {icon && <Icon path={path} size={0.75} color={color} />}
        </Button>
      ) : (
        <IconButton onClick={handleClick} size="small">
          <Icon path={path} size={0.75} color={color} />
        </IconButton>
      )}

      <MaterialUiMenu
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        open={isOpen}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        disableScrollLock={true}
        disableRestoreFocus={true}
        {...menuProps}
      >
        {children}
      </MaterialUiMenu>
    </>
  );
};

export default Menu;
