import React, { useEffect, useState, useRef, useMemo } from "react";
import styles from "./AddEditObjectiveDialog.module.scss";
import _ from "lodash";
import { useQuery, useLazyQuery, useMutation } from "@apollo/client";
import gql from "graphql-tag";
import * as Yup from "yup";
import { useFormik, FormikProvider } from "formik";

import { getUsersByPlan, recursiveMergeCustomizer, toTitleCase } from "../../utils/misc";
import { OBJECTIVE_FIELDS } from "../../utils/fragments";
import { GET_OBJECTIVES } from "../../utils/query";
import { useDepartmentFilter } from "../../context/departmentFilterContext";
import { useAuth } from "../../context/authContext";
import { isAuthed } from "../../utils/authorization";

import {
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  useMediaQuery,
  Tooltip,
  Menu,
  MenuItem,
  CircularProgress,
  useTheme,
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import Icon from "@mdi/react";
import { mdiBrain, mdiTypewriter, mdiUndoVariant } from "@mdi/js";

import Loading from "../Loading/Loading";
import NewSelectObjectives from "../SelectObjectives/NewSelectObjectives";
import NewSelectDepartment from "../SelectDepartment/NewSelectDepartment";
import NewSelectUsers from "../SelectUsers/NewSelectUsers";
import LLMSuggestionsListDialog from "../LLMDialogs/LLMSuggestionsListDialog";
import LLMSettingsDialog from "../LLMDialogs/LLMSettingsDialog";
import useDidUpdateEffect from "../../hooks/useDidUpdateEffect";
import useLLM from "../../hooks/useLLM";

const AddEditObjectiveDialog = ({ dialog, setDialog, requestFetch, fetch, params, org, snack }) => {
  const theme = useTheme();
  const fs = useMediaQuery("(max-width: 600px)");

  const { auth } = useAuth();
  const isAdmin = isAuthed(auth, "vantage facilitator");

  const { departmentFilter } = useDepartmentFilter();
  const deptFilterSpid = _.get(departmentFilter, "sharedPlanId");

  const objectiveDialog = _.get(dialog, "addObjectiveDialog", {});
  const {
    open,
    objective,
    oneYearCorpPlan, // this will be three year corp if it is adding three year
    category,
    variables,
  } = objectiveDialog;

  const isCreate = _.isNil(objective);

  const [close, setClose] = useState(true);

  const [prevValue, setPrevValue] = useState("");
  const [prevDescription, setPrevDescription] = useState("");

  const [createObjective, { loading: mutationLoading }] = useMutation(CREATE_OBJECTIVE, {
    update(cache, { data: { createObjective } }) {
      try {
        if (_.isNil(variables)) {
          throw new Error("variable cannot be null or undefined");
        }
        const newObjective = createObjective.objective;
        const existingData = cache.readQuery({
          query: GET_OBJECTIVES,
          variables,
        });
        cache.writeQuery({
          query: GET_OBJECTIVES,
          variables,
          data: {
            objectives: [newObjective, ...existingData.objectives],
          },
        });
      } catch (e) {
        console.log(e);
      }
    },
  });
  const [updateObjective] = useMutation(UPDATE_OBJECTIVE);

  const {
    data,
    refetch,
    loading: queryLoading,
  } = useQuery(GET_USERS_AND_OBJECTIVES_AND_PLANS, {
    variables: {
      organization: params.org,
      category,
      sharedPlanId: deptFilterSpid,
      oneYearCorpPlan: oneYearCorpPlan,
      closed: _.isNil(oneYearCorpPlan) ? false : null,
    },
  });

  const [getObjectiveSuggestions, { data: objSuggestionsData, loading: objSuggestionsLoading, error: objSuggestionsError }] = useLazyQuery(
    GET_OBJECTIVE_SUGGESTIONS,
    {
      fetchPolicy: "network-only", // Ensures data is always fetched from the server
    }
  );

  const usersByPlan = useMemo(() => {
    if (!data) return {};

    const users = _.get(data, "users", []);
    const plans = _.get(data, "plans", []);
    return getUsersByPlan(users, plans);
  }, [data]);

  const {
    anchorEl,
    llmSuggestions,
    setLlmSuggestions,
    llmSuggestionsListOpen,
    setLlmSuggestionsListOpen,
    llmSettingsOpen,
    setLlmSettingsOpen,
    canUndo,
    setCanUndo,
    abortControllerRef,
    rephraseText,
    rephraseTextData,
    rephraseTextLoading,
    llmSettingsByPurpose,
    handleOpenAdminMenu,
    handleCloseAdminMenu,
    handleCancelBrainstorm,
    handleSaveLLMSettings,
  } = useLLM({ open, snack, suggestionsLoading: objSuggestionsLoading });

  const handleClose = () => {
    setDialog({ ...dialog, addObjectiveDialog: { open: false } });
  };

  // FORMIK START
  const getSchema = (usersByPlan) => {
    return Yup.object().shape({
      value: Yup.string().required("Objective title is required"),
      description: Yup.string(),
      users: Yup.array()
        .of(Yup.string().required("User is required"))
        .test(
          "valid-users-for-plan",
          <>
            One or more selected users are not valid for this department. Click{" "}
            <span className={styles.resetBtn} onClick={() => setFieldValue("users", [])}>
              here
            </span>{" "}
            to reset field.
          </>,
          function (users) {
            const plan = this.parent.plan;

            if (!plan || _.isEmpty(users)) return true; // Skip validation if no plan or no users selected

            const validUsers = _.get(usersByPlan, plan, []);
            const validUserIds = new Set(validUsers.map((user) => user));

            return users.every((userId) => validUserIds.has(userId));
          }
        ),
      objectives: Yup.array().of(Yup.string().required("Objective is required")),
      plan: Yup.string().required("Department is required"),
    });
  };

  const getInitialValues = (objective) => {
    let initVals = {
      value: "",
      description: "",
      users: [],
      objectives: [],
      plan: "",
    };

    if (!_.isNil(objective)) {
      initVals = _.mergeWith({}, initVals, objective, (objVal, srcVal) => recursiveMergeCustomizer(srcVal));
    }

    return initVals;
  };

  const formik = useFormik({
    initialValues: getInitialValues(objective),
    validationSchema: getSchema(usersByPlan),
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      const { value, description, users, objectives, plan } = values;

      try {
        let res;
        if (isCreate) {
          res = await createObjective({
            variables: {
              organization: params.org,
              plan,
              category,
              value,
              description,
              users,
              objectives,
            },
          });
        } else {
          res = await updateObjective({
            variables: {
              id: objective.id,
              value,
              description,
              users,
              objectives,
              plan,
            },
          });
        }

        if (res) {
          snack(`${isCreate ? "Created" : "Updated"} "${value}" objective`);
          if (close) {
            handleClose();
          } else {
            resetForm();
          }
        }
        setSubmitting(false);
      } catch (error) {
        snack(`Failed to ${isCreate ? "create" : "update"} "${value}" objective`, "error");
      }
    },
    enableReinitialize: true,
  });

  const {
    values,
    errors,
    touched,
    handleSubmit: handleSubmitFormik,
    isSubmitting,
    getFieldProps,
    setFieldValue,
    setValues,
    resetForm,
  } = formik;

  const handleSubmit = (close) => {
    setClose(close);
    handleSubmitFormik();
  };

  const handleGenerateSuggestions = (feedbackPrompts) => {
    // Abort any ongoing request before starting a new one
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }

    // Create a new AbortController for the new request
    abortControllerRef.current = new AbortController();

    getObjectiveSuggestions({
      variables: { organization: params.org, user: auth.id, category, objectives: values.objectives, feedbackPrompts },
      context: {
        fetchOptions: { signal: abortControllerRef.current.signal },
      },
    });
  };

  const handleRephraseText = () => {
    setPrevValue(_.get(values, "value", ""));
    setPrevDescription(_.get(values, "description", ""));
    setCanUndo(true);

    rephraseText({
      variables: { text: values.value },
    });
  };

  const handleSelectSuggestion = (suggestion = {}) => {
    setPrevValue(_.get(values, "value", ""));
    setPrevDescription(_.get(values, "description", ""));
    setCanUndo(true);

    const { value, description } = suggestion;
    setValues({ ...values, value, description });
    setLlmSuggestionsListOpen(false);
  };

  const handleUndo = () => {
    setValues({ ...values, value: prevValue, description: prevDescription });
    setCanUndo(false);
  };

  useEffect(() => {
    if (open) {
      refetch();
    } else {
      resetForm();
    }
  }, [open, refetch]);

  useEffect(() => {
    const plans = _.get(data, "plans", []);
    if (open && isCreate && deptFilterSpid) {
      const planWithSameSharedPlanId = _.find(plans, (plan) => plan.sharedPlanId === deptFilterSpid);
      setFieldValue("plan", _.get(planWithSameSharedPlanId, "id", null));
    }
  }, [data, open, isCreate, deptFilterSpid]);

  useEffect(() => {
    if (objSuggestionsData) {
      setLlmSuggestions(_.get(objSuggestionsData, "objectiveSuggestions", []));
    }
  }, [objSuggestionsData]);

  useEffect(() => {
    if (rephraseTextData) {
      setFieldValue("value", _.get(rephraseTextData, "rephrase", ""));
    }
  }, [rephraseTextData]);

  // useEffect(() => {
  //   setLlmSuggestions([]);
  // }, [suggestionsSystemDesc, suggestionsUserPrompt]);

  useDidUpdateEffect(() => {
    setLlmSuggestions([]);
  }, [category]);

  // useDidUpdateEffect(() => {
  //   handleRephraseText();
  // }, [rephraseSystemDesc, rephraseUserPrompt]);

  if (queryLoading)
    return (
      <Dialog
        open={dialog.addObjectiveDialog.open === true}
        onClose={(event, reason) => {
          if (reason !== "backdropClick") {
            handleClose();
          }
        }}
        fullScreen={fs}
        fullWidth
      >
        <Loading />
      </Dialog>
    );

  const isThreeYear = category === "3 year";
  const llmLoading = objSuggestionsLoading || rephraseTextLoading;

  return (
    <>
      <Dialog
        open={open}
        onClose={(event, reason) => {
          if (reason !== "backdropClick") {
            handleClose();
          }
        }}
        fullScreen={fs}
        fullWidth
      >
        <DialogTitle>
          <div className={styles.title}>
            <div style={{ display: "flex", gap: theme.spacing(2), alignItems: "center" }}>
              {isCreate ? "Create" : "Edit"} {toTitleCase(category)} Objective
              {isAdmin && (
                <Button color="primary" variant="outlined" onClick={(e) => handleOpenAdminMenu(e)}>
                  Settings
                </Button>
              )}
            </div>
            <IconButton onClick={handleClose} size="small">
              <Close fontSize="inherit" />
            </IconButton>
          </div>
        </DialogTitle>
        <DialogContent>
          <FormikProvider value={formik}>
            <NewSelectObjectives
              name="objectives"
              multiple={true}
              objectives={_.get(data, "objectives", null)}
              category={isThreeYear ? "3 year" : "1 year"}
              helperText="Which objectives are tied to this one?"
              plansOrder={_.get(data, "organization.plansOrder")}
              formik={formik}
            />
            <TextField
              autoFocus
              fullWidth
              label="Objective Title"
              variant="outlined"
              {...getFieldProps("value")}
              error={Boolean(touched.value && errors.value)}
              helperText={touched.value && errors.value}
              disabled={rephraseTextLoading}
              InputProps={{
                endAdornment: (
                  <>
                    <Tooltip title="Brainstorm">
                      <div>
                        <IconButton onClick={(e) => setLlmSuggestionsListOpen(true)} disabled={llmLoading}>
                          <Icon path={mdiBrain} size={1} />
                        </IconButton>
                      </div>
                    </Tooltip>
                    <Tooltip title="Rephrase">
                      <div>
                        <IconButton onClick={(e) => handleRephraseText()} disabled={_.isEmpty(values.value) || llmLoading}>
                          {rephraseTextLoading ? <CircularProgress size={20} color="inherit" /> : <Icon path={mdiTypewriter} size={1} />}
                        </IconButton>
                      </div>
                    </Tooltip>
                    <Tooltip title="Undo">
                      <div>
                        <IconButton onClick={handleUndo} disabled={!canUndo || llmLoading}>
                          <Icon path={mdiUndoVariant} size={1} />
                        </IconButton>
                      </div>
                    </Tooltip>
                  </>
                ),
              }}
            />
            <TextField label="Why and How" fullWidth multiline {...getFieldProps("description")} variant="outlined" margin="normal" />

            <NewSelectDepartment name="plan" plans={_.get(data, "plans")} formik={formik} />
            <NewSelectUsers
              name="users"
              multiple={true}
              users={_.get(data, "users")}
              handleResetField={() => setFieldValue("users", [])}
              helperText="Who is accountable for this objective?"
              plan={values.plan}
              allPlans={_.get(data, "plans")}
              formik={formik}
            />
          </FormikProvider>
        </DialogContent>
        <DialogActions>
          {isCreate && (
            <Button
              onClick={() => handleSubmit(false)}
              color="primary"
              variant="outlined"
              disabled={mutationLoading || isSubmitting}
              className={styles.button}
            >
              {mutationLoading ? <Loading size={24} color="#fff" /> : "Create & Add Another"}
            </Button>
          )}
          <Button
            onClick={() => handleSubmit(true)}
            color="primary"
            variant="contained"
            disabled={mutationLoading || isSubmitting}
            className={styles.button}
          >
            {mutationLoading ? <Loading size={24} color="#fff" /> : isCreate ? "Create" : "Save"}
          </Button>
        </DialogActions>
      </Dialog>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        onClick={handleCloseAdminMenu}
        onClose={handleCloseAdminMenu}
      >
        <MenuItem onClick={() => setLlmSettingsOpen("brainstorm")}>Brainstorm</MenuItem>
        <MenuItem onClick={() => setLlmSettingsOpen("rephrase")}>Rephrase</MenuItem>
      </Menu>
      <LLMSuggestionsListDialog
        open={llmSuggestionsListOpen}
        handleClose={() => {
          setLlmSuggestionsListOpen(false);
          handleCancelBrainstorm();
        }}
        title={`${toTitleCase(category)} Objective Suggestions`}
        suggestionGroups={llmSuggestions}
        loading={objSuggestionsLoading}
        error={objSuggestionsError}
        handleRefresh={handleGenerateSuggestions}
        handleClickSuggestion={handleSelectSuggestion}
        snack={snack}
        org={org}
      />
      <LLMSettingsDialog
        open={!_.isEmpty(llmSettingsOpen)}
        onClose={() => setLlmSettingsOpen("")}
        type="objectives"
        purpose={llmSettingsOpen}
        title={`LLM System Settings (${_.capitalize(llmSettingsOpen)})`}
        llmSettingsArr={_.get(llmSettingsByPurpose, llmSettingsOpen, [])}
        handleSave={handleSaveLLMSettings}
      />
    </>
  );
};

export default AddEditObjectiveDialog;

const GET_USERS_AND_OBJECTIVES_AND_PLANS = gql`
  query AddObjectiveDialog_GetUsersObjectivesPlans(
    $organization: ID!
    $category: String
    $sharedPlanId: ID
    $oneYearCorpPlan: ID
    $closed: Boolean
  ) {
    organization(id: $organization) {
      id
      plansOrder
    }

    users(organization: $organization) {
      name {
        first
        last
      }
      profilePicture
      id
      plan {
        id
        departmentName
        sharedPlanId
      }
    }

    objectives(organization: $organization, archived: false, sharedPlanId: $sharedPlanId) {
      id
      value
      category
      number
      plan {
        id
        departmentName
        color
        shortName
        sharedPlanId
      }
    }

    plans(organization: $organization, category: $category, oneYearCorpPlan: $oneYearCorpPlan, closed: $closed) {
      id
      departmentName
      sharedPlanId
      category
      year
      plan {
        id
      }
    }
  }
`;

const CREATE_OBJECTIVE = gql`
  ${OBJECTIVE_FIELDS}
  mutation CreateOneYearObj(
    $value: String!
    $description: String
    $organization: ID!
    $plan: ID!
    $category: String!
    $users: [ID!]
    $objectives: [ID!]
  ) {
    createObjective(
      value: $value
      description: $description
      organization: $organization
      plan: $plan
      category: $category
      users: $users
      objectives: $objectives
    ) {
      objective {
        ...ObjectiveFields
      }
      plan {
        id
        objectives
      }
      objectives {
        id
        objectives
      }
    }
  }
`;

const UPDATE_OBJECTIVE = gql`
  ${OBJECTIVE_FIELDS}
  mutation ObjsEditDialog_UpdateObj($id: ID!, $value: String!, $description: String, $users: [ID!], $objectives: [ID!], $plan: ID!) {
    updateObjective(id: $id, value: $value, description: $description, users: $users, objectives: $objectives, plan: $plan) {
      objective {
        ...ObjectiveFields
      }
      objectives {
        id
        number
        objectives
      }
      plans {
        id
        objectives
      }
    }
  }
`;

const GET_OBJECTIVE_SUGGESTIONS = gql`
  query ($organization: ID!, $user: ID!, $category: String!, $objectives: [ID!], $feedbackPrompts: [ID!]) {
    objectiveSuggestions(
      organization: $organization
      user: $user
      category: $category
      objectives: $objectives
      feedbackPrompts: $feedbackPrompts
    ) {
      feedbackPrompt {
        id
        title
      }
      suggestions {
        value
        description
      }
      systemText
      userText
    }
  }
`;
