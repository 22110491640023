import React, { useMemo } from "react";
import styles from "./KanbanBoard.module.scss";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import _ from "lodash";
import { diffInDays } from "../../utils/dates";

import { Chip, Typography, useTheme } from "@material-ui/core";
import { mdiCalendarAlert } from "@mdi/js";
import Icon from "@mdi/react";

import { DragIndicator } from "@material-ui/icons";
import UserAvatars from "../UserAvatars/UserAvatars";

const KanbanCard = ({ item = {}, dispIdFunc, usersDict, plansDict }) => {
  const theme = useTheme();

  const { id, value, position, type, status, dispId, targetDate, user, plan } = item;
  const daysRemaining = targetDate ? diffInDays(targetDate, new Date()) : null; // for success criteria

  const renderDisplayId = useMemo(() => {
    if (!_.isNil(dispIdFunc)) {
      return dispIdFunc({ type, dispId });
    } else {
      return dispId;
    }
  }, [dispIdFunc, type, dispId]);

  const header = useMemo(() => {
    if (type !== "successCriteria" || status === "completed" || _.isNil(daysRemaining) || daysRemaining > 60) return null;

    let bgColor = "unset",
      color = "white";
    if (daysRemaining <= 5) {
      bgColor = "firebrick";
    } else if (daysRemaining <= 30) {
      bgColor = "gold";
      color = "black";
    } else if (daysRemaining <= 60) {
      bgColor = "steelblue";
    }

    return (
      <div
        className={styles.cardHeader}
        style={{ backgroundColor: bgColor, color, gap: theme.spacing(1), borderColor: theme.palette.grey[300] }}
      >
        <Icon path={mdiCalendarAlert} size={0.75} color={color} />
        <Typography variant="subtitle2" style={{ color }}>
          {daysRemaining > 0 ? `${daysRemaining} Days Remaining` : `${Math.abs(daysRemaining)} Days Overdue`}
        </Typography>
      </div>
    );
  }, [type, status, daysRemaining, theme]);

  const planPill = useMemo(() => {
    const planObj = _.get(plansDict, plan, {});
    if (_.isEmpty(planObj)) {
      return null;
    }

    const labelBackup = planObj.departmentName.slice(0, 4);

    return {
      label: _.toUpper(planObj.shortName ?? labelBackup),
      color: planObj.color || theme.palette.secondary.main,
    };
  }, [plansDict, plan, theme]);

  const { setNodeRef, setActivatorNodeRef, listeners, transform, transition, isDragging } = useSortable({
    id,
    data: {
      type: "Card",
      item,
    },
  });

  const dndStyle = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  const userObj = _.get(usersDict, user, null);

  return (
    <div
      ref={setNodeRef}
      className={styles.card}
      style={{
        backgroundColor: theme.palette.background.paper,
        borderColor: theme.palette.grey[300],
        visibility: isDragging ? "hidden" : "visible",
        ...dndStyle,
      }}
    >
      {header}
      <div className={styles.cardContent} style={{ gap: theme.spacing(1), padding: theme.spacing(1.5) }}>
        {/* <span>{id}</span> */}
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Typography>
            <strong>{value}</strong>
          </Typography>
          <DragIndicator ref={setActivatorNodeRef} className={styles.dragHandle} {...listeners} />
        </div>
        <div style={{ display: "flex", gap: theme.spacing(0.5) }}>
          <Chip label={_.startCase(type)} color="primary" size="small" />
          {planPill && (
            <Chip
              label={planPill.label}
              style={{ backgroundColor: planPill.color, color: theme.palette.getContrastText(planPill.color) }}
              size="small"
            />
          )}
          {/* <Chip label={position} color="secondary" size="small" /> */}
        </div>
        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
          {renderDisplayId}
          <UserAvatars users={userObj ? [userObj] : []} />
        </div>
      </div>
    </div>
  );
};

export default KanbanCard;
