import { MenuItem, Tooltip, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import _ from "lodash";
import PlanPill from "../../components/PlanPill/PlanPill";
import styles from "./Dashboard.module.scss";
import {
  mdiAlertDecagram,
  mdiBullseyeArrow,
  mdiChartTimelineVariant,
  mdiCheckboxMarked,
  mdiLinkVariantOff,
  mdiPoll,
  mdiRhombus,
  mdiRhombusSplit,
} from "@mdi/js";
import { Looks3, LooksOne, LooksTwo } from "@material-ui/icons";
import Menu from "../../components/Menu/Menu";
import { getTodoTieIn } from "../../utils/graphql";
import Icon from "@mdi/react";

const iconPaths = {
  SuccessCriteria: mdiRhombusSplit,
  Rock: mdiRhombus,
  Metric: mdiPoll,
  Objective: mdiChartTimelineVariant,
  Issue: mdiAlertDecagram,
  Todo: mdiCheckboxMarked,
  WeeklyTarget: mdiBullseyeArrow,
  0: <LooksOne />,
  1: <LooksTwo />,
  2: <Looks3 />,
};

const DashboardTodo = ({ todo, idx, canEdit, handleEditTodo, handleDeleteTodo }) => {
  const [tieIn, setTieIn] = useState();
  useEffect(() => {
    if (todo.referenceModel) {
      (async () => {
        const model = todo.referenceModel === "Issue" ? "Todo" : todo.referenceModel;

        const tieIn = await getTodoTieIn(todo.referenceId, model);
        const value = _.get(tieIn, `data.${_.camelCase(model)}.value`);

        setTieIn(value);
      })();
    }
  }, []);
  return (
    <div style={{ backgroundColor: idx % 2 === 1 ? "#f8f8f8" : "#ffffff" }}>
      <div className={styles.itemDoc}>
        {todo.category === "todo" ? (
          <span className={styles.itemLabelTodo}>TODO</span>
        ) : (
          <span className={styles.itemLabelIssue}>ISSUE</span>
        )}{" "}
        <Typography variant="body2" className={styles.itemValue}>
          {todo.value}
        </Typography>
        <span className={styles.itemButton}>
          <span style={{ marginRight: 4, display: "flex", alignItems: "center" }}>
            <Tooltip
              title={
                todo.referenceModel ? (
                  <Typography variant="body2">
                    <span className={styles.label}>
                      {_.startCase(todo.referenceModel)}: <br />
                    </span>
                    {tieIn}
                  </Typography>
                ) : (
                  <Typography variant="body2">No tie in</Typography>
                )
              }
            >
              <Icon path={iconPaths[todo.referenceModel] || mdiLinkVariantOff} size={1} color="rgba(0, 0, 0, 0.54)" />
            </Tooltip>
          </span>
          <span style={{ marginRight: 4, fontSize: 10 }}>
            <PlanPill plan={todo.plan} noIndent={true} noMargin={true} />
          </span>
          <span
            className={
              todo.priority === "high" ? styles.itemLabelHighP : todo.priority === "medium" ? styles.itemLabelMedP : styles.itemLabelLowP
            }
          >
            {todo.priority.toUpperCase()}
          </span>
          {canEdit && (
            <Menu>
              <MenuItem onClick={handleEditTodo(true, todo)}>Edit</MenuItem>
              <MenuItem onClick={handleDeleteTodo(todo)} className={styles.delete}>
                Delete
              </MenuItem>
            </Menu>
          )}
        </span>
      </div>
    </div>
  );
};

export default DashboardTodo;
