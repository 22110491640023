import React, { useState, useEffect, useContext } from "react";
import { Routes, Route, useParams, useLocation } from "react-router-dom";
import styles from "./Layout.module.scss";
import { useQuery } from "@apollo/client";
import { useMediaQuery } from "@material-ui/core";
import gql from "graphql-tag";
import { useAuth } from "../context/authContext";
import { DialogContext } from "../context/dialogContext";
import { FetchContext } from "../context/fetchContext";
import { SnackbarContext } from "../context/snackbarContext";
import Sidebar from "../components/Sidebar/Sidebar";
import Navbar from "../components/Navbar/Navbar";
import Loading from "../components/Loading/Loading";
import { Inactive, Error } from "../views";
import { routes } from "../routes";
import { isVantageAdmin } from "../utils/authorization";
import _ from "lodash";

import AddPlanDialog from "../components/AddPlanDialog/AddPlanDialog";
import AddEditObjectiveDialog from "../components/AddEditObjectiveDialog/AddEditObjectiveDialog";
import AddEditMetricDialog from "../components/AddEditMetricDialog/AddEditMetricDialog";
import AddEditRockDialog from "../components/AddEditRockDialog/AddEditRockDialog";
import AddEditSuccessCriteriaDialog from "../components/AddEditSuccessCriteriaDialog/AddEditSuccessCriteriaDialog";
import AddTodoDialog from "../components/AddTodoDialog/AddTodoDialog";
import AddWeeklyTargetDialog from "../components/AddWeeklyTargetDialog/AddWeeklyTargetDialog";
import AddMeetingDialog from "../components/AddMeetingDialog/AddMeetingDialog";
import Notes from "../components/Notes/Notes";
import ChatComponent from "../components/ChatComponent/ChatComponent";

const Layout = () => {
  const isDesktop = useMediaQuery("(min-width: 960px)");
  const params = useParams();
  const location = useLocation();

  const { auth } = useAuth();
  const { dialog, setDialog } = useContext(DialogContext);
  const { requestFetch, fetch } = useContext(FetchContext);
  const { snack } = useContext(SnackbarContext);

  const { loading, data } = useQuery(GET_ORGANIZATION, { variables: { id: params.org } });
  const [drawer, setDrawer] = useState(isDesktop);
  const { loading: orgsLoading, data: orgsData } = useQuery(GET_ORGANIZATIONS, {
    variables: { ids: _.get(auth, "organizations", []) },
  });
  const handleDrawer = () => {
    setDrawer(!drawer);
  };

  useEffect(() => {
    const isMeetingPage = location.pathname.split("/").includes("meeting");
    if (isDesktop && !isMeetingPage) {
      setDrawer(true);
    } else {
      setDrawer(false);
    }
  }, [isDesktop, location]);

  if (loading || orgsLoading) return <Loading fullscreen size={128} />;
  if (!data || !data.organization) return <Error title="Unauthorized" body="You do not have permission to proceed" hasNavigation />;

  if (!data.organization.active && !isVantageAdmin(auth)) return <Inactive name={data.organization.name} />;

  return (
    <>
      <Navbar
        dialog={dialog}
        setDialog={setDialog}
        org={data.organization}
        params={params}
        handleOpen={handleDrawer}
        snack={snack}
        open={drawer}
        orgs={orgsData.organizations}
      />
      <Sidebar params={params} org={data.organization} open={drawer} handleClose={handleDrawer} isDesktop={isDesktop} />
      <div className={isDesktop ? (drawer ? styles.content : styles.contentFullscreen) : styles.contentFullscreen}>
        <Routes>
          {routes.map((route) => {
            const { path, name, props, component: Component } = route;
            if (_.isNil(path) || _.isNil(Component)) {
              return null;
            }
            return <Route path={`${path}`} element={<Component org={data.organization} params={params} {...props} />} key={name} />;
          })}
        </Routes>
      </div>

      <AddPlanDialog dialog={dialog} setDialog={setDialog} requestFetch={requestFetch} params={params} snack={snack} />
      <AddEditObjectiveDialog
        dialog={dialog}
        setDialog={setDialog}
        requestFetch={requestFetch}
        fetch={fetch}
        params={params}
        org={data.organization}
        snack={snack}
      />
      <AddEditMetricDialog
        dialog={dialog}
        setDialog={setDialog}
        requestFetch={requestFetch}
        fetch={fetch}
        params={params}
        org={data.organization}
        snack={snack}
      />
      <AddEditRockDialog
        dialog={dialog}
        setDialog={setDialog}
        requestFetch={requestFetch}
        fetch={fetch}
        params={params}
        org={data.organization}
        snack={snack}
      />
      <AddEditSuccessCriteriaDialog
        dialog={dialog}
        setDialog={setDialog}
        requestFetch={requestFetch}
        params={params}
        org={data.organization}
        snack={snack}
      />
      <AddTodoDialog dialog={dialog} setDialog={setDialog} fetch={fetch} requestFetch={requestFetch} params={params} snack={snack} />
      <AddWeeklyTargetDialog dialog={dialog} setDialog={setDialog} requestFetch={requestFetch} params={params} snack={snack} />
      <AddMeetingDialog dialog={dialog} setDialog={setDialog} requestFetch={requestFetch} fetch={fetch} params={params} snack={snack} />

      <Notes snack={snack} dialog={dialog} setDialog={setDialog} fetch={fetch} />

      {auth.id && <ChatComponent userId={auth.id} />}
    </>
  );
};

export default Layout;

const GET_ORGANIZATION = gql`
  query Layout_GetOrg($id: ID!) {
    organization(id: $id) {
      id
      name
      active
      fiscalYear
      plansOrder
      viewPreferences {
        name
        position
        show
      }
    }
  }
`;

const GET_ORGANIZATIONS = gql`
  query ($ids: [ID!]) {
    organizations(ids: $ids) {
      id
      name
      fiscalYear
      active
    }
  }
`;
