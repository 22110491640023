import React from "react";
import styles from "./WeeklyReview.module.scss";
import _ from "lodash";
import { differenceInMinutes } from "date-fns";
import { Dialog, DialogContent, DialogTitle, IconButton, Typography, List, ListItem, ListItemText } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { formatAs } from "../../utils/dates";

const HistoryDialog = ({ open, handleClose, meeting: { title, currentSession, endTime, rating, weeklyTargets, rocks, todos, issues } }) => {
  if (!open) return null;

  const date = formatAs(_.get(currentSession, "startTime", new Date()), "MMM d, yyyy");
  const duration = `${formatAs(currentSession.startTime, "h:mm a")} - ${formatAs(endTime, "h:mm a")} (${differenceInMinutes(
    new Date(parseInt(endTime)),
    new Date(parseInt(currentSession.startTime))
  )} minutes)`;
  const attendees = currentSession.users.map((u) => `${u.name.first} ${u.name.last}`);
  const overallRating = Math.round((rating.reduce((sum, val) => sum + val) / rating.length) * 10) / 10;

  return (
    <Dialog
      open={open}
      onClose={(event, reason) => {
        if (reason !== 'backdropClick') {
          handleClose()
        }
      }}
      fullWidth
    >
      <DialogTitle>
        <div className={styles.flexSpaceBetween}>
          {title}
          <div>
            <IconButton onClick={handleClose} size="small">
              <CloseIcon fontSize="inherit" />
            </IconButton>
          </div>
        </div>
      </DialogTitle>

      <DialogContent>
        <Typography variant="h6">Meeting Info</Typography>
        <List dense>
          <ListItem dense>
            <ListItemText primary="Date" secondary={date} />
          </ListItem>
          <ListItem>
            <ListItemText primary="Duration" secondary={duration} />
          </ListItem>
          <ListItem>
            <ListItemText primary="Attendees" secondary={attendees.join(", ")} />
          </ListItem>
          <ListItem>
            <ListItemText primary="Rating" secondary={`${overallRating} out of 5`} />
          </ListItem>
        </List>

        <Typography variant="h6">KPIs</Typography>
        <List dense>
          {weeklyTargets &&
            weeklyTargets.map(({ id, value }) => {
              return (
                <ListItem key={id}>
                  <ListItemText>{value}</ListItemText>
                </ListItem>
              );
            })}
        </List>

        <Typography variant="h6">Rock Review</Typography>
        <List dense>
          {rocks &&
            rocks.map(({ id, value }) => {
              return (
                <ListItem key={id}>
                  <ListItemText>{value}</ListItemText>
                </ListItem>
              );
            })}
        </List>

        <Typography variant="h6">To Do List</Typography>
        <List dense>
          {todos &&
            todos.map(({ id, value }) => {
              return (
                <ListItem key={id}>
                  <ListItemText>{value}</ListItemText>
                </ListItem>
              );
            })}
        </List>

        <Typography variant="h6">Issue Solving</Typography>
        <List dense>
          {issues &&
            issues.map(({ id, value }) => {
              return (
                <ListItem key={id}>
                  <ListItemText>{value}</ListItemText>
                </ListItem>
              );
            })}
        </List>
      </DialogContent>
    </Dialog>
  );
};

export default HistoryDialog;
