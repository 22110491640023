import React, { useEffect, useState } from "react";
import styles from "./AddEditRockDialog.module.scss";
import _ from "lodash";
import { isDate, startOfToday } from "date-fns";
import { before } from "../../utils/dates";
import { formatAs } from "../../utils/dates";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { useLazyQuery } from "@apollo/client";
import gql from "graphql-tag";
import useLLM from "../../hooks/useLLM";
import { useAuth } from "../../context/authContext";

import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemSecondaryAction,
  Icon,
  Divider,
  IconButton,
  Button,
  FormControl,
  Tooltip,
  useTheme,
} from "@material-ui/core";
import { Icon as MdiIcon } from "@mdi/react";
import { mdiDrag, mdiBrain, mdiUndoVariant } from "@mdi/js";

import LLMSuggestionsListDialog from "../LLMDialogs/LLMSuggestionsListDialog";
import OutlinedSection from "../OutlinedSection/OutlinedSection";

function SuccessCriteriaList({
  editingRockId,
  rockValues = {},
  successCriteria = [],
  onChange,
  error,
  handleToBeDeleted,
  dialog,
  setDialog,
  corpPlanId,
  open,
  snack,
  org,
}) {
  const theme = useTheme();
  const { auth } = useAuth();

  const [successCriteriaList, setSuccessCriteriaList] = useState(successCriteria);
  const [prevSuccessCriteriaList, setPrevSuccessCriteriaList] = useState(successCriteria);

  const [getScSuggestions, { data: scSuggestionsData, loading: scSuggestionsLoading, error: scSuggestionsError }] = useLazyQuery(
    GET_SC_SUGGESTIONS,
    {
      fetchPolicy: "network-only", // Ensures data is always fetched from the server
    }
  );

  const {
    anchorEl,
    llmSuggestions,
    setLlmSuggestions,
    llmSuggestionsListOpen,
    setLlmSuggestionsListOpen,
    llmSettingsOpen,
    setLlmSettingsOpen,
    canUndo,
    setCanUndo,
    abortControllerRef,
    rephraseText,
    rephraseTextData,
    rephraseTextLoading,
    llmSettingsByPurpose,
    handleOpenAdminMenu,
    handleCloseAdminMenu,
    handleCancelBrainstorm,
    handleSaveLLMSettings,
  } = useLLM({ open, snack, suggestionsLoading: scSuggestionsLoading });

  const handleRemove = (i) => {
    let newSCs = _.cloneDeep(successCriteriaList);
    const [removedSc] = newSCs.splice(i, 1);
    if (handleToBeDeleted && _.get(removedSc, "id")) {
      handleToBeDeleted(removedSc);
    }

    setSuccessCriteriaList(newSCs);
  };

  const handleAddEdit = (indexToEdit) => (values) => {
    let newSCs = _.cloneDeep(successCriteriaList);
    if (_.isNil(indexToEdit)) {
      newSCs.push(values);
    } else {
      let scToUpdate = newSCs[indexToEdit];
      _.set(newSCs, [indexToEdit], { ...scToUpdate, ...values });
    }
    setSuccessCriteriaList(newSCs);
  };

  const handleEditOpen = (index) => {
    setDialog({
      ...dialog,
      addSuccessCriteriaDialog: {
        open: true,
        planId: corpPlanId,
        successCriteria: _.get(successCriteria, [index.toString()], null),
        rock: editingRockId,
        rockDialog: {
          handleSubmit: handleAddEdit(index),
        },
      },
    });
  };

  const handleCreateOpen = () => {
    setDialog({
      ...dialog,
      addSuccessCriteriaDialog: {
        open: true,
        rock: editingRockId,
        rockDialog: {
          handleSubmit: handleAddEdit(),
        },
      },
    });
  };

  const handleDragEnd = ({ draggableId, destination, source }) => {
    if (_.isEqual(source, destination)) return;

    let newSCs = _.cloneDeep(successCriteriaList);

    const [srcItem] = newSCs.splice(source.index, 1);
    newSCs.splice(destination.index, 0, srcItem);

    setSuccessCriteriaList(newSCs);
  };

  const handleGenerateScSuggestions = (feedbackPrompts) => {
    // Abort any ongoing request before starting a new one
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }

    // Create a new AbortController for the new request
    abortControllerRef.current = new AbortController();

    getScSuggestions({
      variables: { organization: org.id, user: auth.id, rockValue: rockValues.value, rockObjective: rockValues.objective, feedbackPrompts },
      context: {
        fetchOptions: { signal: abortControllerRef.current.signal },
      },
    });
  };

  const handleSelectScSuggestions = (suggestionsObj) => {
    const scSuggestions = _.get(suggestionsObj, "suggestions", []);

    setPrevSuccessCriteriaList(successCriteriaList);
    setSuccessCriteriaList(scSuggestions.map((scSuggestion) => _.omit({ ...scSuggestion, done: false }, "__typename")));

    setLlmSuggestionsListOpen(false);
    setCanUndo(true);
  };

  const handleUndo = () => {
    setSuccessCriteriaList(prevSuccessCriteriaList);
    setCanUndo(false);
  };

  useEffect(() => {
    if (scSuggestionsData) {
      setLlmSuggestions(_.get(scSuggestionsData, "successCriteriaSuggestions", []));
    }
  }, [scSuggestionsData]);

  useEffect(() => {
    onChange(successCriteriaList);
  }, [JSON.stringify(successCriteriaList)]);

  // useEffect(() => {
  //   if (rephraseTextData) {
  //     setFieldValue("value", _.get(rephraseTextData, "rephrase", ""));
  //   }
  // }, [rephraseTextData]);

  const llmLoading = scSuggestionsLoading || rephraseTextLoading;
  const brainstormDisabled = llmLoading || _.isEmpty(rockValues.value);
  const undoDisabled = llmLoading || !canUndo;

  return (
    <>
      <OutlinedSection title={<div className={styles.inputLabel}>Success Criteria</div>} style={{ padding: `0 ${theme.spacing(1)}px` }}>
        <DragDropContext onDragEnd={handleDragEnd}>
          <Droppable droppableId="SCF" type="successCriteriaForm">
            {(provided, snapshot) => (
              <List {...provided.droppableProps} ref={provided.innerRef}>
                {_.isEmpty(successCriteriaList) ? (
                  <ListItem className={styles.scListItem}>
                    <ListItemText>No success criteria at the moment</ListItemText>
                  </ListItem>
                ) : (
                  successCriteriaList.map((sc, index) => {
                    const targetDate = sc.targetDate;
                    return (
                      <Draggable key={sc.value} draggableId={`SCF_${sc.id}_${sc.value}`} index={index}>
                        {(provided, snapshot) => (
                          <div ref={provided.innerRef} {...provided.draggableProps} style={{ ...provided.draggableProps.style }}>
                            <ListItem className={styles.scListItem}>
                              <ListItemIcon className={styles.listIcon}>
                                <div {...provided.dragHandleProps}>
                                  <MdiIcon path={mdiDrag} size={1} />
                                </div>
                              </ListItemIcon>
                              <ListItemText style={{ maxWidth: "80%" }}>
                                <div style={{ textDecoration: _.get(sc, "done", false) ? "line-through" : "none" }}>
                                  {_.get(sc, `value`, "")}
                                </div>
                                <div className={before(targetDate, startOfToday()) ? styles.targetDatePast : styles.targetDate}>
                                  {targetDate && `(${formatAs(targetDate, "MMM dd, yyyy")})`}
                                </div>
                              </ListItemText>
                              <ListItemSecondaryAction>
                                <IconButton onClick={() => handleEditOpen(index)}>
                                  <Icon>edit</Icon>
                                </IconButton>
                                <IconButton onClick={() => handleRemove(index)}>
                                  <Icon>delete</Icon>
                                </IconButton>
                              </ListItemSecondaryAction>
                            </ListItem>
                            <Divider />
                          </div>
                        )}
                      </Draggable>
                    );
                  })
                )}
                {provided.placeholder}
              </List>
            )}
          </Droppable>
        </DragDropContext>
        <div style={{ padding: `${theme.spacing(1)}px 0`, display: "flex", gap: theme.spacing(1), alignItems: "center" }}>
          <Button fullWidth variant="outlined" color="primary" onClick={handleCreateOpen}>
            <Icon>add_circle</Icon> Add
          </Button>
          <Tooltip title={`Brainstorm${brainstormDisabled ? " (please enter rock description)" : ""}`}>
            <div>
              <Button variant="outlined" color="primary" onClick={(e) => setLlmSuggestionsListOpen(true)} disabled={brainstormDisabled}>
                <MdiIcon path={mdiBrain} size={1} color={brainstormDisabled ? theme.palette.action.disabled : theme.palette.primary.main} />
              </Button>
            </div>
          </Tooltip>
          <Tooltip title="Undo">
            <div>
              <Button variant="outlined" color="primary" onClick={handleUndo} disabled={undoDisabled}>
                <MdiIcon path={mdiUndoVariant} size={1} color={undoDisabled ? theme.palette.action.disabled : theme.palette.primary.main} />
              </Button>
            </div>
          </Tooltip>
        </div>
      </OutlinedSection>
      <LLMSuggestionsListDialog
        open={llmSuggestionsListOpen}
        handleClose={() => {
          setLlmSuggestionsListOpen(false);
          handleCancelBrainstorm();
        }}
        title="Success Criteria Suggestions"
        suggestionGroups={llmSuggestions}
        loading={scSuggestionsLoading}
        error={scSuggestionsError}
        handleRefresh={handleGenerateScSuggestions}
        handleClickFeedback={handleSelectScSuggestions}
        snack={snack}
        org={org}
      />
    </>
  );
}

export default SuccessCriteriaList;

const GET_SC_SUGGESTIONS = gql`
  query SuccessCriteriaListSuggestions($organization: ID!, $user: ID!, $rockValue: String!, $rockObjective: ID!, $feedbackPrompts: [ID!]) {
    successCriteriaSuggestions(
      organization: $organization
      user: $user
      rockValue: $rockValue
      rockObjective: $rockObjective
      feedbackPrompts: $feedbackPrompts
    ) {
      feedbackPrompt {
        id
        title
      }
      suggestions {
        value
      }
      systemText
      userText
    }
  }
`;
