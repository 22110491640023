import React, { useEffect, useState, useMemo } from "react";
import _ from "lodash";
import styles from "./LLMDialogs.module.scss";
import gql from "graphql-tag";
import { useQuery, useMutation } from "@apollo/client";
import { useUser } from "../../context/userContext";
import useCorpPlan from "../../hooks/useCorpPlan";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  ListSubheader,
  List,
  ListItem,
  Typography,
  ListItemText,
  CircularProgress,
  Collapse,
  useTheme,
  IconButton,
  Menu,
  MenuItem,
  Chip,
  Tooltip,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
} from "@material-ui/core";
import { Add, Edit, Error, ExpandLess, ExpandMore, Info, Warning, Close } from "@material-ui/icons";

import AddEditFeedbackPromptDialog from "./AddEditFeedbackPromptDialog";
import useDidUpdateEffect from "../../hooks/useDidUpdateEffect";
import { useDepartmentFilter } from "../../context/departmentFilterContext";

const LLMSuggestionsListDialog = ({
  open,
  handleClose,
  title,
  info,
  suggestionGroups = [],
  loading,
  error,
  handleClickFeedback,
  handleClickSuggestion,
  handleRefresh,
  snack,
  org = {},
}) => {
  const { id: orgId, fiscalYear } = org;

  const theme = useTheme();
  const { user, refreshUser } = useUser();
  const { departmentFilter } = useDepartmentFilter();
  const { corpForSelectedYear } = useCorpPlan({ orgId, fiscalYear });

  const [anchorEl, setAnchorEl] = useState(null);
  const [expandedSuggestions, setExpandedSuggestions] = useState({});

  const [feedbackPromptDialogOpen, setFeedbackPromptDialogOpen] = useState(false);
  const [feedbackPromptToEdit, setFeedbackPromptToEdit] = useState();
  const [showRefreshIndicator, setShowRefreshIndicator] = useState(false);

  const [suggestionsGroupToPreview, setSuggestionsGroupToPreview] = useState();

  const {
    data: feedbackPromptsData,
    refetch: feedbackPromptsRefetch,
    loading: feedbackPromptsLoading,
  } = useQuery(GET_ALL_FEEDBACK_PROMPTS, {
    variables: {
      organization: orgId,
      user: user.id,
      plan: departmentFilter.id,
    },
  });

  const {
    data: plansData,
    refetch: plansRefetch,
    loading: plansLoading,
  } = useQuery(GET_PLANS, {
    variables: {
      organization: orgId,
      corpPlan: corpForSelectedYear.id,
    },
  });

  const [createFeedbackPrompt, { loading: createFeedbackPromptLoading }] = useMutation(CREATE_FEEDBACK_PROMPT);
  const [updateFeedbackPrompt, { loading: updateFeedbackPromptLoading }] = useMutation(UPDATE_FEEDBACK_PROMPT);
  const [deleteFeedbackPrompt, { loading: deleteFeedbackPromptLoading }] = useMutation(DELETE_FEEDBACK_PROMPT);
  const [updateUser, { loading: updateUserLoading }] = useMutation(UPDATE_USER);

  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleRemoveUserFeedbackPrompt = async (feedbackPromptId) => {
    const newUserFeedbackPrompts = user.llmFeedbackPrompts.filter(({ id }) => id !== feedbackPromptId).map(({ id }) => id);

    try {
      const res = await updateUser({
        variables: {
          id: user.id,
          llmFeedbackPrompts: newUserFeedbackPrompts,
        },
      });

      if (res) {
        snack("Successfully removed user feedback prompt");
        refreshUser(user.id);
      }
    } catch (error) {
      snack("Failed to remove user feedback prompt", "error");
    }
  };

  const handleAddUserFeedbackPrompt = async (feedbackPromptId) => {
    const newUserFeedbackPrompts = [...user.llmFeedbackPrompts.map(({ id }) => id), feedbackPromptId];

    try {
      const res = await updateUser({
        variables: {
          id: user.id,
          llmFeedbackPrompts: newUserFeedbackPrompts,
        },
      });

      if (res) {
        snack("Successfully added user feedback prompt");
        refreshUser(user.id);
      }
    } catch (error) {
      snack("Failed to add user feedback prompt", "error");
    }
  };

  const handleCreateFeedbackPrompt = async (newFeedbackPrompt) => {
    const { title, prompt, access } = newFeedbackPrompt;

    try {
      const res = await createFeedbackPrompt({
        variables: {
          title,
          prompt,
          access,
        },
      });

      if (res) {
        snack("Successfully created feedback prompt");
        feedbackPromptsRefetch();
        setFeedbackPromptDialogOpen(false);
      }
    } catch (error) {
      snack("Failed to create feedback prompt", "error");
    }
  };

  const handleUpdateFeedbackPrompt = async (newFeedbackPrompt) => {
    const { title, prompt, access } = newFeedbackPrompt;

    try {
      const res = await updateFeedbackPrompt({
        variables: {
          id: _.get(feedbackPromptToEdit, "id"),
          title,
          prompt,
          access,
        },
      });

      if (res) {
        snack("Successfully updated feedback prompt");
        feedbackPromptsRefetch();
        refreshUser(user.id);
        setFeedbackPromptDialogOpen(false);
      }
    } catch (error) {
      snack("Failed to update feedback prompt", "error");
    }
  };

  const handleDeleteFeedbackPrompt = async () => {
    try {
      const res = await deleteFeedbackPrompt({
        variables: {
          id: _.get(feedbackPromptToEdit, "id"),
        },
      });

      if (res) {
        snack("Successfully deleted feedback prompt");
        feedbackPromptsRefetch();
        refreshUser(user.id);
        setFeedbackPromptDialogOpen(false);
      }
    } catch (error) {
      snack("Failed to delete feedback prompt", "error");
    }
  };

  const allFeedbackPrompts = _.get(feedbackPromptsData, "llmFeedbackPrompts", []);
  const allFeedbackPromptIds = allFeedbackPrompts.map((feedbackPrompt) => feedbackPrompt.id);

  const userFeedbackPrompts = _.get(user, "llmFeedbackPrompts", []).filter(({ id }) => allFeedbackPromptIds.includes(id));
  const userFeedbackPromptIds = userFeedbackPrompts.map((userFeedbackPrompt) => userFeedbackPrompt.id);

  const filteredFeedbackPrompts = _.differenceBy(allFeedbackPrompts, userFeedbackPrompts, "id");
  const isCreateFeedbackPrompt = _.isNil(feedbackPromptToEdit);

  useDidUpdateEffect(() => {
    setShowRefreshIndicator(true);
  }, [JSON.stringify(userFeedbackPrompts)]);

  useEffect(() => {
    setExpandedSuggestions({});
    setShowRefreshIndicator(false);
  }, [JSON.stringify(suggestionGroups)]);

  return (
    <>
      <Dialog
        open={open}
        onClose={(event, reason) => {
          if (reason !== "backdropClick") {
            handleClose();
          }
        }}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>
          <div className={styles.flexTitle}>
            <div className={styles.flexContainer} style={{ gap: theme.spacing(1) }}>
              <Typography variant="h6">{title}</Typography>
              {info && (
                <Tooltip title={info}>
                  <Info color="primary" />
                </Tooltip>
              )}
            </div>
            <Button
              color="primary"
              variant="outlined"
              onClick={() => {
                setFeedbackPromptToEdit();
                setFeedbackPromptDialogOpen(true);
              }}
            >
              New
            </Button>
          </div>
        </DialogTitle>
        <DialogContent>
          <div style={{ display: "flex", flexWrap: "wrap", gap: theme.spacing(1), alignItems: "center" }}>
            <Chip color="primary" icon={<Add />} label="Add" onClick={handleOpenMenu} />
            {userFeedbackPrompts.map((feedbackPrompt) => {
              const { id, title } = feedbackPrompt;
              return (
                <Chip
                  key={id}
                  color="primary"
                  variant="outlined"
                  label={title}
                  onClick={() => {
                    setFeedbackPromptToEdit(feedbackPrompt);
                    setFeedbackPromptDialogOpen(true);
                  }}
                  onDelete={() => handleRemoveUserFeedbackPrompt(id)}
                />
              );
            })}
            {showRefreshIndicator && (
              <Tooltip title="Changes made to user feedback prompts, please click REFRESH" placement="right">
                <Warning color="primary" />
              </Tooltip>
            )}
          </div>
          {loading ? (
            <div className={styles.flexContainer} style={{ gap: theme.spacing(1), padding: theme.spacing(2) }}>
              <CircularProgress />
              <Typography>Generating suggestions, please wait...</Typography>
            </div>
          ) : error ? (
            <div className={styles.flexContainer} style={{ gap: theme.spacing(1), padding: theme.spacing(2) }}>
              <Error style={{ color: theme.palette.error.main }} />
              <Typography>Error generating suggestions, please click REFRESH</Typography>
            </div>
          ) : _.isEmpty(suggestionGroups) ? (
            <div className={styles.flexContainer} style={{ padding: theme.spacing(2) }}>
              <Typography>Please click REFRESH to generate suggestions for each feedback prompt</Typography>
            </div>
          ) : (
            <div
              style={{
                display: "flex",
                gap: theme.spacing(2),
                padding: `${theme.spacing(2)}px 0`,
                width: "100%",
                overflow: "auto",
              }}
            >
              {suggestionGroups.map((suggestionGroup, groupIdx) => {
                const { suggestions, feedbackPrompt } = suggestionGroup;
                const feedbackPromptId = _.get(feedbackPrompt, "id");

                return (
                  <List
                    style={{ minWidth: `calc(33.33% - ${theme.spacing(2)}px)`, flex: 1 }}
                    subheader={
                      <ListSubheader style={{ display: "flex", alignItems: "center" }}>
                        <Typography
                          onClick={() => {
                            if (!_.isNil(handleClickFeedback)) handleClickFeedback(suggestionGroup);
                          }}
                          className={_.isNil(handleClickFeedback) ? "" : styles.clickable}
                          style={{ fontWeight: "bold", backgroundColor: "white" }}
                          color="secondary"
                        >
                          {_.get(feedbackPrompt, "title", "")}
                        </Typography>
                        <IconButton onClick={() => setSuggestionsGroupToPreview(suggestionGroup)} className={styles.clickable}>
                          <Info color="secondary" />
                        </IconButton>
                      </ListSubheader>
                    }
                  >
                    {suggestions.map((suggestion, idx) => {
                      const { value, description } = suggestion;

                      return (
                        <>
                          <ListItem key={idx} button={!_.isNil(handleClickSuggestion)}>
                            <ListItemText
                              primary={
                                <strong>
                                  {idx + 1}. {value}
                                </strong>
                              }
                              onClick={() => {
                                if (!_.isNil(handleClickSuggestion)) handleClickSuggestion(suggestion);
                              }}
                              className={_.isNil(handleClickSuggestion) ? "" : styles.clickable}
                            />
                            {description && (
                              <IconButton
                                onClick={() => {
                                  const expandedSuggestionsCopy = _.clone(expandedSuggestions);
                                  const currVal = !!_.get(expandedSuggestionsCopy, [feedbackPromptId, idx.toString()]);
                                  _.set(expandedSuggestionsCopy, [feedbackPromptId, idx.toString()], !currVal);
                                  setExpandedSuggestions(expandedSuggestionsCopy);
                                }}
                              >
                                {expandedSuggestions[idx] ? <ExpandLess /> : <ExpandMore />}
                              </IconButton>
                            )}
                          </ListItem>
                          <Collapse in={_.get(expandedSuggestions, [feedbackPromptId, idx.toString()], false)}>
                            <div style={{ padding: `0 ${theme.spacing(2)}px` }}>{description}</div>
                          </Collapse>
                        </>
                      );
                    })}
                  </List>
                );
              })}
            </div>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button
            onClick={() => handleRefresh(userFeedbackPromptIds)}
            color="primary"
            variant="contained"
            disabled={loading || _.isEmpty(userFeedbackPrompts)}
          >
            Refresh
          </Button>
        </DialogActions>
      </Dialog>
      <Menu
        anchorEl={anchorEl}
        getContentAnchorEl={null} // needed to get anchorOrigin and transformOrigin to work properly for menu
        open={Boolean(anchorEl)}
        onClick={handleCloseMenu}
        onClose={handleCloseMenu}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        {_.isEmpty(filteredFeedbackPrompts) ? (
          <MenuItem disabled={true}>No feedback prompts available</MenuItem>
        ) : (
          filteredFeedbackPrompts.map((feedbackPrompt) => {
            const { id, title } = feedbackPrompt;
            return (
              <MenuItem
                key={id}
                value={id}
                onClick={() => handleAddUserFeedbackPrompt(id)}
                style={{ display: "flex", justifyContent: "space-between", gap: theme.spacing(2), paddingTop: 0, paddingBottom: 0 }}
              >
                <span>{title}</span>
                <IconButton
                  style={{ padding: theme.spacing(1) }}
                  onClick={(e) => {
                    e.stopPropagation();
                    setFeedbackPromptToEdit(feedbackPrompt);
                    setFeedbackPromptDialogOpen(true);
                  }}
                >
                  <Edit />
                </IconButton>
              </MenuItem>
            );
          })
        )}
      </Menu>
      <AddEditFeedbackPromptDialog
        open={feedbackPromptDialogOpen}
        handleClose={() => setFeedbackPromptDialogOpen(false)}
        feedbackPrompt={feedbackPromptToEdit}
        handleSubmit={isCreateFeedbackPrompt ? handleCreateFeedbackPrompt : handleUpdateFeedbackPrompt}
        handleDelete={handleDeleteFeedbackPrompt}
        user={user}
        plans={_.get(plansData, "plans", [])}
        orgId={orgId}
      />
      <PromptPreviewDialog
        open={!_.isNil(suggestionsGroupToPreview)}
        handleClose={() => setSuggestionsGroupToPreview()}
        suggestionsGroup={suggestionsGroupToPreview}
        theme={theme}
      />
    </>
  );
};

export default LLMSuggestionsListDialog;

const GET_ALL_FEEDBACK_PROMPTS = gql`
  query ($organization: ID!, $user: ID!, $plan: ID) {
    llmFeedbackPrompts(organization: $organization, user: $user, plan: $plan) {
      id
      title
      prompt
      access {
        id
        level
      }
    }
  }
`;

const GET_PLANS = gql`
  query ($organization: ID!, $corpPlan: ID) {
    plans(organization: $organization, category: "1 year", oneYearCorpPlan: $corpPlan) {
      id
      departmentName
      sharedPlanId
    }
  }
`;

const CREATE_FEEDBACK_PROMPT = gql`
  mutation ($title: String!, $prompt: String!, $access: LLMFeedbackPromptAccessInput!) {
    createLLMFeedbackPrompt(title: $title, prompt: $prompt, access: $access) {
      title
      prompt
      access {
        id
        level
      }
    }
  }
`;

const UPDATE_FEEDBACK_PROMPT = gql`
  mutation ($id: ID!, $title: String!, $prompt: String!, $access: LLMFeedbackPromptAccessInput!) {
    updateLLMFeedbackPrompt(id: $id, title: $title, prompt: $prompt, access: $access) {
      title
      prompt
      access {
        id
        level
      }
    }
  }
`;

const DELETE_FEEDBACK_PROMPT = gql`
  mutation ($id: ID!) {
    deleteLLMFeedbackPrompt(id: $id)
  }
`;

const UPDATE_USER = gql`
  mutation ($id: ID!, $llmFeedbackPrompts: [ID!]) {
    updateUser(id: $id, llmFeedbackPrompts: $llmFeedbackPrompts) {
      id
      llmFeedbackPrompts {
        id
        title
        prompt
        access {
          id
          level
        }
      }
    }
  }
`;

function PromptPreviewDialog({ handleClose, open, suggestionsGroup = {}, theme }) {
  const { systemText, userText } = suggestionsGroup;

  return (
    <Dialog
      maxWidth="md"
      open={open}
      onClose={(event, reason) => {
        if (reason !== "backdropClick") {
          handleClose();
        }
      }}
    >
      <DialogTitle>
        <div className={styles.flexTitle}>
          <span>Prompt Preview</span>
          <IconButton onClick={handleClose} size="small">
            <Close fontSize="inherit" />
          </IconButton>
        </div>
      </DialogTitle>
      <DialogContent style={{ paddingBottom: theme.spacing(3) }}>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMore />}>
            <Typography variant="subtitle1">System Description</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Box
              style={{
                padding: theme.spacing(2),
                border: "1px solid",
                borderRadius: "4px",
                backgroundColor: theme.palette.grey["50"],
              }}
            >
              <Typography style={{ whiteSpace: "pre-wrap" }} variant="body2">
                {systemText}
              </Typography>
            </Box>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMore />}>
            <Typography variant="subtitle1">User Prompt</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Box
              style={{
                padding: theme.spacing(2),
                border: "1px solid",
                borderRadius: "4px",
                backgroundColor: theme.palette.grey["50"],
              }}
            >
              <Typography style={{ whiteSpace: "pre-wrap" }} variant="body2">
                {userText}
              </Typography>
            </Box>
          </AccordionDetails>
        </Accordion>
      </DialogContent>
    </Dialog>
  );
}
