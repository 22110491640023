import React, { useContext, useEffect, useMemo, useState } from "react";
import _ from "lodash";
import styles from "./KanbanBoard.module.scss";
import { SnackbarContext } from "../../context/snackbarContext";
import { closestCorners, DndContext, DragOverlay, PointerSensor, useSensor, useSensors } from "@dnd-kit/core";
import { arrayMove, horizontalListSortingStrategy, SortableContext } from "@dnd-kit/sortable";
import useDidUpdateEffect from "../../hooks/useDidUpdateEffect";

import { useTheme } from "@material-ui/core";

import KanbanColumn from "./KanbanColumn";
import { createPortal } from "react-dom";
import KanbanCard from "./KanbanCard";
import KanbanColumnVirtualized from "./KanbanColumnVirtualized";
import { useMutation } from "@apollo/client";

const normalizeKanbanItem = ({ item, itemFieldKeyDict, itemFieldValDict }) => {
  const type = item.type;

  const positionKey = _.get(itemFieldKeyDict, [type, "position"], "position");
  const statusKey = _.get(itemFieldKeyDict, [type, "status"], "status");
  const dispIdKey = _.get(itemFieldKeyDict, [type, "dispId"], "dispId");

  let positionVal = _.get(item, positionKey);
  positionVal = _.get(itemFieldValDict, [type, "position", positionVal], positionVal);

  let statusVal = _.get(item, statusKey);
  statusVal = _.get(itemFieldValDict, [type, "status", statusVal], statusVal);

  let dispIdVal = _.get(item, dispIdKey);
  dispIdVal = _.get(itemFieldValDict, [type, "dispId", dispIdVal], dispIdVal);

  const normalizedItem = _.omit(item, [positionKey, statusKey, dispIdKey, "__typename"]);
  return { ...normalizedItem, position: positionVal, status: statusVal, dispId: dispIdVal };
};

const getNextAvailKanbanPos = (maxPos) => {
  return Math.ceil(maxPos / 1000) * 1000 + 1000;
};

const KanbanBoard = ({
  columns = [],
  filters,
  itemFieldKeyDict,
  itemFieldValDict,
  columnDataQuery,
  updateItemMutation,
  queryName,
  mutationName,
  localStorageKey = "kanbanColumnOrder",
  sort = "desc",
  cardDispIdFunc,
  usersDict,
  plansDict,
}) => {
  const theme = useTheme();
  const { snack } = useContext(SnackbarContext);

  const [itemsDict, setItemsDict] = useState({});

  const [refetch, setRefetch] = useState(false);
  const [canFetch, setCanFetch] = useState(true);

  const [columnTotals, setColumnTotals] = useState({});
  const [columnArr, setColumnArr] = useState(() => {
    const saved = localStorage.getItem(localStorageKey);
    return saved ? JSON.parse(saved) : columns;
  });
  const [expandedCols, setExpandedCols] = useState(
    columns.reduce((accum, col) => {
      const colId = col.id;
      if (!_.has(accum, colId)) {
        accum[colId] = true;
      }
      return accum;
    }, {})
  );

  const [activeColumn, setActiveColumn] = useState(null); // the column being dragged
  const [activeCard, setActiveCard] = useState(null); // the card being dragged
  const [sourceColumn, setSourceColumn] = useState(null); // the initial column of the card being dragged
  // const [overCardHistory, setOverCardHistory] = useState([]); // the list of cards hovered during a drag and drop instance

  const [moveKanbanCard] = useMutation(updateItemMutation);

  const columnIds = useMemo(() => {
    return columnArr.map((col) => col.id);
  }, [columnArr]);

  // const sensors = useSensors(
  //   useSensor(PointerSensor, {
  //     activationConstraint: {
  //       distance: 5, // need to drag for 5px for the dnd to trigger
  //     },
  //   })
  // );

  const clearTrackingStates = () => {
    setActiveColumn(null);
    // setOverCardHistory([]);
    setActiveCard(null);
    setSourceColumn(null);
  };

  const handleExpandColumn = (colId) => {
    setExpandedCols((prev) => ({
      ...prev,
      [colId]: !prev[colId],
    }));
  };

  const handleNewlyLoadedItems = (status) => (newItems) => {
    const normalizedItems = newItems.map((item) => normalizeKanbanItem({ item, itemFieldKeyDict, itemFieldValDict }));

    setItemsDict((prev) => {
      const dictCopy = _.cloneDeep(prev);

      /* 
      // Uncomment the following if newItems contains multiple status items
      // Group normalized items by status for easy merging
      const groupedByStatus = _.groupBy(normalizedItems, "status");

      // Iterate over groups and merge it into the overall items dictionary
      _.forEach(groupedByStatus, (items, status) => {
        // do below inside here
      });
      */

      // Create a dictionary of existing status items using id as key
      const statusColDict = _.keyBy(_.get(dictCopy, status, []), "id");

      // Merge or add new items
      normalizedItems.forEach((item) => {
        statusColDict[item.id] = item; // Replace or insert
      });

      // Update the overall items dicionary with merged values
      dictCopy[status] = _.orderBy(_.values(statusColDict), "position", sort);

      return dictCopy;
    });
  };

  const handleUpdateKanban = async (itemUpdate) => {
    const { type, position } = itemUpdate;

    try {
      const res = await moveKanbanCard({ variables: { ...itemUpdate, targetPosition: position } });
      const data = _.get(res, ["data", mutationName]);

      if (data) {
        setItemsDict((prev) => {
          return _.mapValues(prev, (items) =>
            _.map(items, (item) => (item.id === data.id ? normalizeKanbanItem({ item: data, itemFieldKeyDict, itemFieldValDict }) : item))
          );
        });
        snack(`Updated ${_.lowerCase(type)}`);
      }
    } catch (err) {
      console.log({ err });
      setRefetch(true);
      snack(`Error updating ${_.lowerCase(type)}`, "error");
    }
  };

  const handleDragStart = (event) => {
    const { active } = event;
    const dragRef = active.data.current;

    if (dragRef.type === "Column") {
      setActiveColumn(dragRef.column);
    } else if (dragRef.type === "Card") {
      setActiveCard(dragRef.item);
      setSourceColumn(_.get(dragRef, "item.status"));
    }
    return;
  };

  const handleDragOver = (event) => {
    const { active, over } = event;

    if (!over) return;

    const activeId = active.id;
    const overId = over.id;

    if (activeId === overId) {
      /* Handles edge case where when card is dragged to ends of a column the active and over are the same,
      // null is added to "over" history to detect this */
      // setOverCardHistory((prev) => [...prev, null]);
      return;
    }

    const activeRef = active.data.current;
    const overRef = over.data.current;

    const activeType = activeRef.type;
    const overType = overRef.type;

    /* **NOTE: Problem when using virtualized columns - the active ref data sometimes gets lost during cross column dragging
    // but the id remains */

    if (activeType === "Card") {
      const activeCol = _.get(activeRef, "item.status");

      let overCol = overId;
      if (overType === "Card") {
        // setOverCardHistory((prev) => [...prev, overRef.item]);
        overCol = _.get(overRef, "item.status");
      }

      if (activeCol !== overCol) {
        /* The setTimeout is needed to prevent an error related to the maximum update depth being exceeded
        // when swapping cards to a different column */
        setTimeout(() => {
          setItemsDict((prev) => {
            const dictCopy = _.cloneDeep(prev);

            const itemToMove = _.remove(dictCopy[activeCol], (item) => item.id === activeId)[0];
            _.set(itemToMove, "status", overCol);

            if (_.has(dictCopy, overCol)) {
              dictCopy[overCol].push(itemToMove);
            } else {
              dictCopy[overCol] = [itemToMove];
            }

            return dictCopy;
          });
        }, 0);
      }
    }
  };

  const handleDragEnd = (event) => {
    const { active, over } = event;

    if (!over) {
      clearTrackingStates();
      return;
    }

    const activeId = active.id;
    const overId = over.id;

    const activeRef = active.data.current;
    const overRef = over.data.current;

    const activeType = activeRef.type;
    const overType = overRef.type;

    if (activeType === "Column" && overType === "Column") {
      if (activeId === overId) {
        clearTrackingStates();
        return;
      }

      setColumnArr((cols) => {
        const activeIdx = _.findIndex(cols, ["id", activeId]);
        const overIdx = _.findIndex(cols, ["id", overId]);

        return arrayMove(cols, activeIdx, overIdx);
      });
    } else if (activeType === "Card") {
      const destinationColumn = overType === "Card" ? _.get(overRef, "item.status") : overId;
      const sameColReorder = sourceColumn === destinationColumn;
      const currPos = _.get(activeCard, "position");

      setItemsDict((prev) => {
        const dictCopy = _.cloneDeep(prev);
        const destColItems = dictCopy[destinationColumn];

        let newDestColItems = destColItems;
        if (activeId !== overId) {
          /* When active id is equal to over id, user is attempting to move it to the end of another column which 
          // is already done when items are hovered over a different column. Therefore only do reorder when this is not
          // the case */
          const destColActiveItemIdx = _.findIndex(destColItems, ["id", activeId]);
          const destColOverItemIdx = _.findIndex(destColItems, ["id", overId]);

          newDestColItems = arrayMove(destColItems, destColActiveItemIdx, destColOverItemIdx);
        }

        const movedItemIdx = _.findIndex(newDestColItems, ["id", activeId]);

        const prevNeighbor = movedItemIdx > 0 ? newDestColItems[movedItemIdx - 1] : null;
        const nextNeighbor = movedItemIdx < newDestColItems.length - 1 ? newDestColItems[movedItemIdx + 1] : null;

        const prevPos = _.get(prevNeighbor, "position", null);
        const nextPos = _.get(nextNeighbor, "position", null);

        let newPos = 1000; // default position for when items are moved to empty columns
        if (_.isNil(prevPos) && !_.isNil(nextPos)) {
          newPos = sort === "desc" ? getNextAvailKanbanPos(nextPos) : Math.round(nextPos / 2);
        } else if (_.isNil(nextPos) && !_.isNil(prevPos)) {
          newPos = sort === "desc" ? Math.round(prevPos / 2) : getNextAvailKanbanPos(prevPos);
        } else if (!_.isNil(prevPos) && !_.isNil(nextPos)) {
          newPos = Math.round((prevPos + nextPos) / 2);
        }

        if (currPos !== newPos || !sameColReorder) {
          _.set(newDestColItems, [movedItemIdx.toString(), "position"], newPos);

          const update = { id: activeId, position: newPos, type: _.get(activeRef, "item.type") };
          if (!sameColReorder) {
            _.set(update, "status", destinationColumn);

            setColumnTotals((prev) => {
              return {
                ...prev,
                [sourceColumn]: _.get(prev, sourceColumn, 0) - 1,
                [destinationColumn]: _.get(prev, destinationColumn, 0) + 1,
              };
            });
          }

          handleUpdateKanban(update);
        }

        return { ...dictCopy, [destinationColumn]: newDestColItems };
      });
    }

    clearTrackingStates();
  };

  function customCollisionDetectionAlgorithm({ droppableContainers, ...args }) {
    const { active } = args;
    const activeType = _.get(active, "data.current.type");

    const validDroppables = droppableContainers.filter((droppable) => {
      let extraCond = false;
      if (activeType === "Card") {
        const colItems = _.get(droppable, "data.current.column.items");
        extraCond = _.isArray(colItems) && _.isEmpty(colItems);
      }

      return _.get(droppable, "data.current.type") === activeType || extraCond;
    });

    return closestCorners({
      ...args,
      droppableContainers: validDroppables,
    });
  }

  useEffect(() => {
    setCanFetch(false);
    setItemsDict({});
  }, [filters]);

  useEffect(() => {
    if (refetch) {
      setCanFetch(false);
      setItemsDict({});
    }
  }, [refetch]);

  useEffect(() => {
    if (canFetch === false && _.isEmpty(itemsDict)) {
      setCanFetch(true);
      setRefetch(false);
    }
  }, [canFetch, itemsDict]);

  useEffect(() => {
    localStorage.setItem(localStorageKey, JSON.stringify(columnArr));
  }, [columnArr, localStorageKey]);

  return (
    <DndContext
      // sensors={sensors}
      // collisionDetection={closestCorners}
      collisionDetection={customCollisionDetectionAlgorithm}
      onDragStart={handleDragStart}
      onDragOver={handleDragOver}
      onDragEnd={handleDragEnd}
    >
      <div className={styles.board} style={{ gap: theme.spacing(2) }}>
        <SortableContext items={columnIds} strategy={horizontalListSortingStrategy}>
          {columnArr.map((column) => {
            const { id: status } = column;

            return (
              <KanbanColumn
                key={status}
                column={column}
                filters={filters}
                canFetch={canFetch}
                items={_.get(itemsDict, status, [])}
                handleNewlyLoadedItems={handleNewlyLoadedItems(status)}
                expanded={_.get(expandedCols, status, true)}
                handleExpand={() => handleExpandColumn(status)}
                columnDataQuery={columnDataQuery}
                queryName={queryName}
                total={_.get(columnTotals, status, 0)}
                handleSetTotal={(total) => setColumnTotals((prev) => ({ ...prev, [status]: total }))}
                cardDispIdFunc={cardDispIdFunc}
                usersDict={usersDict}
                plansDict={plansDict}
              />
            );
          })}
        </SortableContext>
      </div>
      {createPortal(
        <DragOverlay>
          {activeColumn && (
            <KanbanColumn
              column={_.omit(activeColumn, "items")}
              items={activeColumn.items}
              expanded={true}
              total={_.get(columnTotals, activeColumn?.id, 0)}
              columnDataQuery={columnDataQuery}
              cardDispIdFunc={cardDispIdFunc}
              usersDict={usersDict}
              plansDict={plansDict}
            />
          )}
          {activeCard && <KanbanCard item={activeCard} dispIdFunc={cardDispIdFunc} usersDict={usersDict} plansDict={plansDict} />}
        </DragOverlay>,
        document.body
      )}
    </DndContext>
  );
};

export default KanbanBoard;
